import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import client1 from '../../assets/images/client-1.jpg'
import client2 from '../../assets/images/client-2.jpg'
import client3 from '../../assets/images/professional-img.png'
import client4 from '../../assets/images/about-img.jpg'
import client5 from '../../assets/images/images-2.jpeg'

const Benefit = ({bgColor="bg-gray-50", textColor="text-gray-900"}) => {
    
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
    };
    return ( 
            <section className={`${bgColor}`}>
                <div className="max-w-screen-sm px-1 py-8 text-center ">
                <Slider {...settings}>
                    <figure className="mx-auto">
                        <blockquote>
                            <p className={`font-medium`} style={{color:textColor}}><b>Cost Savings: </b><br/>Exchange skills without spending money.</p>
                        </blockquote>
                    </figure>
                    <figure className="mx-auto">
                        <blockquote>
                            <p className={`font-medium`} style={{color:textColor}}><b>Networking Opportunities:</b><br/> Connect with like-minded individuals and expand professional networks.</p>
                        </blockquote>
                    </figure>
                    <figure className="mx-auto">
                        <blockquote>
                            <p className={`font-medium`} style={{color:textColor}}><b>Progress Tracking:</b><br/> Easily monitor and manage project progress with built-in tools.</p>
                        </blockquote>
                    </figure>
                    <figure className="mx-auto">
                        <blockquote>
                            <p className={`font-medium`} style={{color:textColor}}><b>Resource Efficiency: </b><br/> Utilize existing skills and resources effectively without monetary exchange</p>
                        </blockquote>
                    </figure>
                    <figure className="mx-auto">
                        <blockquote>
                            <p className={`font-medium`} style={{color:textColor}}><b>Maximized Resources:</b><br/>Leverage your existing skills to acquire new ones without additional costs.</p>
                        </blockquote>
                    </figure>
                </Slider>
            </div>
        </section>
     );
}
 
export default Benefit