import React, { useState } from 'react';
import defaultImage from '../../../assets/images/userImage/defaultUserImage.png';
import AcceptOfferForm from './AcceptOfferForm';
import axios from 'axios';

const OfferCardComponent = ({ offer, userData, renderView = null }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [isNotInterested, setIsNotInterested] = useState(false);
  const [isAccept, setIsAccept] = useState(false)
  const [isQuestion, setIsQuestion] = useState(false)
  const [artificialBid, setArtificialBid] = useState({swapId:offer})
  const userImage = offer.user.profileImage ? offer.user.profileImage : defaultImage;
  //console.log(artificialBid)
 
  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleAcceptClick = (q) => {
      setIsQuestion(q)
      setIsAccept(prevState => !prevState);
  };

  const handleEditClick = (offer) => {
    if(offer.user !== offer.partner){
        alert("This Swap is now an active project and can no longer be deleted.")
        return;
    }
    renderView("edit_swap",offer._id)
  };

  const handleDelete = async (offer) => {
    if(offer.user !== offer.partner){
        alert("This Swap is now an active project and can no longer be deleted.")
        return;
    }
    const confirmed = prompt("Do you really want to delete this Swap Request? Type 'yes' to confirm.");

    if (confirmed && confirmed.toLowerCase() === 'yes') {
      try {
        const response = await fetch(`https://www.swaptime.co/swaptime_server/swap/deleteSwapRequest/${offer._id}`, {
          method: 'GET', // Use DELETE if your backend supports it
          headers: {
            'Content-Type': 'application/json',
          },
        });
        //console.log(response);
        if (response.ok) {
          const result = await response.json();
          setIsDeleted(true);
          alert(`${offer.swapName} has been deleted`);
        } else {
          const error = await response.json();
          alert(`Error deleting swap offer: ${error.message}`);
        }
      } catch (error) {
        console.error('Error deleting swap offer:', error);
        alert('An error occurred while trying to delete the swap offer.');
      }
    } else {
      console.log('Delete action canceled.');
    }
  };

  const handleNotInterestedClick = async (offer) => {
    //const confirmed = prompt("Are you really nto interested this Swap Request? Type 'yes' to confirm.");

    //if (confirmed && confirmed.toLowerCase() === 'yes') {
      try {
        let info = {userId:userData._id, swapId:offer._id}
        const response = await axios.post("https://www.swaptime.co/swaptime_server/swap/addNotInterestedUser", JSON.stringify(info) ,{
          headers: {
            'Content-Type': 'application/json',
          },
        });
        //console.log("The info: ", JSON.stringify(info));
        //console.log("The response: ", response);
        if (response.status === 200) {
          setIsNotInterested(true);
        } else {
          alert(`Error updating interest status for this swap Proposal: ${response.data.message}`);
        }
      } catch (error) {
        console.error('Error updating interest status for this swap Proposal:', error);
        alert('An error occurred while trying to delete the swap offer.');
      }
    /*} else {
      console.log('Delete action canceled.');
    }*/
  };

  function humanDate(isoDate) {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const date = new Date(isoDate);
    return date.toLocaleDateString(undefined, options);
  }

  return (
    <>
      {!isDeleted && !isNotInterested && (
        <div className="card m-2 col-md-4 bg-dark text-dark" style={{ width: '18rem' }}>
          <div style={{ maxHeight: '250px' }}>
            <center>
              <img src={userImage} className="card-img-top mt-2 mb-1" alt={offer.user.name} />
            </center>
          </div>
          <div className="card-body bg-light rounded mb-2">
            <b className="card-title mb-3 fs-4">{offer.user.name}</b>
            <br />
            <span>
              <b>Location: </b>
              {offer.user.country.name}
            </span>
            <br />
            <table className="table table-responsive w-100" style={{overflow:"auto"}}>
              <tbody>
                <tr>
                  <td>Swap Name:</td>
                  <td>{offer.swapName}</td>
                </tr>
                <tr>
                  <td>Swap Description:</td>
                  <td>{offer.swapDesc}</td>
                </tr>
                <tr>
                  <td>Swap Proposal:</td>
                  <td>{offer.swapSkill.name}</td>
                </tr>
                <tr>
                  <td>Swap Offer:</td>
                  <td>{offer.swapNeedSkill.name}</td>
                </tr>
                <tr>
                  <td>I need this by:</td>
                  <td>{humanDate(offer.endDate)}</td>
                </tr>
              </tbody>
            </table>
            <br />
            <br />
            {userData._id === offer.user._id ? (
              <>
                {
                  
                  offer.user._id !== offer.partner._id?(
                    <div>
                      <span className="badge badge-primary w-100 mt-2 mb-1">In Progress</span>
                      <button onClick={()=>renderView("set_milestone", artificialBid)} className='btn btn-md w-100 btn-primary'>Go to Project</button>
                    </div>
                  ):(
                    <>
                      <span className="badge badge-warning w-100 mt-2 mb-1">Awaiting Offer</span>
                      
                      <div className='d-grid d-block'>
                          <center>
                          <button onClick={() => handleEditClick(offer)} className="btn btn-primary w-100 btn-md mt-2">Edit</button>
                          </center>
                      </div>
                      <div className='d-grid d-block mb-2 mt-1'>
                          <center>
                            <button onClick={() => handleDelete(offer)} className="btn btn-danger w-100 btn-md">Delete</button>
                          </center>
                      </div>
                    </>
                  )
                }
              </>
            ) : (
              <>
                {
                  offer.user._id !== offer.partner._id?(
                    <div>
                      <span className="badge badge-primary w-100 mt-2 mb-1">In Progress</span>
                      <button onClick={()=>renderView("set_milestone", artificialBid)} className='btn btn-md w-100 btn-primary'>Go to Project</button>
                    </div>
                  ):(
                    <button onClick={handleModalOpen} className="btn btn-primary w-100">
                      See More
                    </button>
                  )
                }
              </>
            )}
          </div>

          {isModalOpen && (
            <div className="modal show d-block" tabIndex="-1" role="dialog">
              <div className="modal-dialog modal-dialog-scrollable" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title"><b>{offer.user.name}</b><br/><small>{offer.user.country.name}</small></h5>
                    <button type="button" className="btn-close" onClick={handleModalClose} aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    {
                      isAccept && 
                      <div>
                          <AcceptOfferForm offer={offer} userData={userData} renderView={renderView} question={isQuestion}/>
                          <center><button type="button" onClick={()=> handleAcceptClick(false)} className='btn btn-secondary btn-sm w-25'>Back</button></center>
                      </div>
                    }
                    { 
                      !isAccept &&
                      <div>
                        <b className="card-title mb-3 fs-4">{offer.user.name}</b>
                        <br />
                        <span>
                          <b>Location: </b>
                          {offer.user.country.name}
                        </span>
                        <br />
                        <table className="table table-striped table-responsive">
                          <tbody>
                            <tr>
                              <td>Rating:</td>
                              <td>4.6/5</td>
                            </tr>
                            <tr>
                              <td>Skill I want Trade:</td>
                              <td>{offer.swapSkill.name}</td>
                            </tr>
                            <tr>
                              <td>Skill I Require:</td>
                              <td>{offer.swapNeedSkill.name}</td>
                            </tr>
                            <tr>
                              <td>Project Description:</td>
                              <td>{offer.swapDesc}</td>
                            </tr>
                            <tr>
                              <td>My Offer:</td>
                              <td>{offer.swapProposal.toString()}</td>
                            </tr>
                            <tr>
                              <td>I need this by:</td>
                              <td>{humanDate(offer.endDate)}</td>
                            </tr>
                          </tbody>
                        </table>
                        <br />
                        <br />
                        {userData._id === offer.user._id ? (
                          <>
                          </>
                        ) : (
                          <>
                            <button onClick={()=> handleAcceptClick(false)} className="btn btn-success w-100 btn-md mt-2 mb-1">
                              Interested
                            </button>
                            <button onClick={()=> handleAcceptClick(true)} className="btn btn-primary w-100 btn-md mb-1">
                              I have a Question
                            </button>
                            <button onClick={()=> handleNotInterestedClick(offer)} className="btn btn-warning w-100 btn-md mb-2">
                              Not Interested
                            </button>
                          </>
                        )}
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default OfferCardComponent;
