import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import useFetch from '../custom_hooks/useFetch';
import loader1 from '../../assets/images/03-42-11-849_512.gif';
import ViewSwapRequests from './ViewSwapRequest';
import { Tabs, Tab, Nav } from 'react-bootstrap';
import MilestoneLogic from './utilityComponents/MilestoneLogic';

const SetMilestones = ({ userData, bid, renderView = null }) => {
    const [key, setKey] = useState('tab1');
    const [type, setType] = useState(null);
    const [swap, setSwap] = useState(bid.swapId);
    const [partner, setPartner] = useState({})

    useEffect(() => {
        if (userData._id === swap.user._id) {
            setType('user');
            setPartner(swap.partner)
        } else {
            setType('bidPartner');
            setPartner(swap.user)
        }
    }, [type, partner]);
    //console.log("User: ", JSON.stringify(swap.user,null,4));
    //console.log("Partner: ", JSON.stringify(swap.partner,null,4))
    return (

        <div className="container mt-4">
            <h3>Set Milestones</h3>
            <span className="fs-2 fw-bold">{swap.swapName}</span><br/><small><b>Project Owner: </b>{swap.user.name}</small><br/><br/>
            <Nav variant="pills" activeKey={key} onSelect={(k) => setKey(k)} className="mb-3">
                <Nav.Item>
                    <Nav.Link eventKey="tab1" className={`bg-${key === 'tab1' ? 'dark' : 'light'} text-${key === 'tab1' ? 'white' : 'dark'}`}>
                        <b>My Milestones</b>
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="tab2" className={`bg-${key === 'tab2' ? 'dark' : 'light'} text-${key === 'tab2' ? 'white' : 'dark'}`}>
                        {
                            partner?(
                                <b>{partner.name} Milestone</b>
                            ):(
                                <b>Swap Partner Milestone</b>
                            )
                        }
                    </Nav.Link>
                </Nav.Item>
            </Nav>
            <hr/>
            <div className="mt-1">
                {key === 'tab1' && (
                    <MilestoneLogic userData={userData} bid={bid} renderView={renderView} type={type} />
                )}
                {key === 'tab2' && (
                    <MilestoneLogic userData={userData} bid={bid} renderView={renderView} type={type === 'user' ? 'bidPartner' : 'user'} />
                )}
            </div>
        </div>
    );
};

export default SetMilestones;
