import { useState, useEffect } from 'react';
import axios from 'axios';

const useFetch = (url) => {
    const [result, setResult] = useState([]);
    const [isResultPending, setIsResultPending] = useState(true);
    const [resultError, setResultError] = useState(null);

    useEffect(() => {
        // Check if URL is valid
        if (!url) return;

        const abortCont = new AbortController(); // Create AbortController

        const fetchData = async () => {
            setIsResultPending(true); // Set loading state at the beginning
            try {
                const response = await axios.get(url, { signal: abortCont.signal });
                console.log("Response from useFetch: ", response);

                setResult(response.data);  // Successfully received data
                setIsResultPending(false); // Done loading
                setResultError(null);      // Clear any previous errors
            } catch (e) {
                if (e.name === 'AbortError') {
                    console.log("Fetch Aborted");
                } else {
                    setResultError(e.message); // Set error message
                    setIsResultPending(false); // Stop pending state on error
                }
            }
        };

        fetchData();

        // Cleanup: Abort fetch if component unmounts
        return () => {
            console.log("Cleanup: Aborting fetch...");
            abortCont.abort();
        };
    }, [url]); // Effect depends on URL

    return { result, isResultPending, resultError };
};

export default useFetch;
