import React, { useState, useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import useFetch from '../custom_hooks/useFetch';
import loader1 from '../../assets/images/03-42-11-849_512.gif';
import ChatTextBox from './utilityComponents/ChatTextBox';
import axios from 'axios';

const BidPageView = ({ userData, renderView }) => {
  const [data, setData] = useState([]); // State to store data
  const [currentView, setCurrentView] = useState(null); // State to track the current view
  const [hide, setHide] = useState(false);
  const [deletedBid, setDeletedBid] = useState(null)

  //const [cl]
  const { result, isResultPending, isResultError } = useFetch("https://www.swaptime.co/swaptime_server/bid/getBids");
  const seen = new Set();


  const messagesEndRef = useRef(null); // Ref for scrolling

  // Fetch data from the API
  useEffect(() => {
    const fetchData = async () => {
      if (!isResultPending && result && result.bids) {
        const filteredData = result.bids.filter((item) => 
          (item.swapId.user._id === userData._id) || (item.bidPartner._id === userData._id)
        );
        setData(filteredData);
      }
      if (isResultError) {
        console.error(JSON.stringify(isResultError));
      }
    };

    fetchData();
  }, [result, isResultPending, isResultError]);

  /*// Function to add new bid to the state
  const handleUpdateResult = (newBid) => {
    setData(prevData => [...prevData, newBid]); // Update state with new bid
  };*/

  const handleUpdateResult = (bid, action) => {
    setData(prevData => {
      if (action === 'add') {
        const existingBidIndex = prevData.findIndex(existingBid => existingBid._id === bid._id);
        if (existingBidIndex === -1) {
          return [...prevData, bid];
        }
        return prevData; // Don't add if it already exists
      } else if (action === 'remove') {
        setDeletedBid(currentView)
        setHide(true)
        return prevData.filter(existingBid => existingBid._id !== bid._id);
      }
      return prevData; // Do nothing if action is invalid
    });
  };

  // Function to scroll to the bottom
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  // Scroll to the bottom when data changes (new messages)
  useEffect(() => {
    scrollToBottom();
  }, [data]);


  const updateSeenStatus = async (swapId) => {
    try {
      const updateData = { receiverId: userData._id, swapId: swapId };
      const response = await axios.post("https://www.swaptime.co/swaptime_server/bid/update-seen-status", updateData, {
        headers: { 'Content-Type': 'application/json' },
      });
      //console.log("Response is: ", response);
      if (response.status === 201) {
        console.log("Seen status updated");
      }
    } catch (e) {
      console.log("Update Error: ", e);
    }
  };

  // useEffect to update seen status when current view changes
  useEffect(() => {
    if (currentView) {
      const selectedItem = data.find(item => item._id === currentView);
      if (selectedItem) {
        // Update seen status for all messages in the selected swap
        data
          .filter(item => item.swapId._id === selectedItem.swapId._id && item.senderId._id !== userData._id)
          .forEach(filteredItem => {
            updateSeenStatus(filteredItem.swapId._id);
          });
      }
    }
  }, [currentView, data, userData]);

  const handleChatView = (item) =>{
    setHide(false)
    setCurrentView(item._id)
  }

  const getViewComponent = () => {
    const selectedItem = data.find(item => item._id === currentView);
  
    if (!selectedItem) {
      return <p>Please select an item from the list.</p>;
    }
  
    return !hide ? (
      <div>
        <div className='p-2 bg-secondary text-white rounded-sm'>
          <h4 className='d-flex justify-content-start'>
            {selectedItem.swapId?.swapName}
          </h4>
          <h6 className='d-flex justify-content-start'>
            {selectedItem.receiverId?._id === userData._id ? (
              <span>{selectedItem.senderId?.name}</span>
            ) : (
              <span>{selectedItem.receiverId?.name}</span>
            )}
          </h6>
          {selectedItem.swapId.user._id !== selectedItem.swapId.partner._id && selectedItem.swapId.partner._id === userData._id && <div className='alert alert-dark w-100'> <b>This Swap Project Active...</b></div>}
          <hr />
        </div>
        
        <div className='p-2 bg-secondary' style={{ height: '350px', overflow: 'auto' }}>
          {data
            .filter(item => item.swapId?._id === selectedItem.swapId?._id)
            .map(filteredItem => (
              <div key={filteredItem._id}>
                {filteredItem.senderId?._id === userData._id ? (
                  <div className='d-flex justify-content-end mb-2'>
                    <div className='p-3 bg-success text-white rounded'>
                      {filteredItem.message}
                    </div>
                  </div>
                ) : (
                  <div className='d-flex justify-content-start mb-2'>
                    <div className='p-3 bg-light text-dark rounded'>
                      {filteredItem.message}
                    </div>
                  </div>
                )}
              </div>
            ))}
          <div ref={messagesEndRef} />
        </div>
  
        <div className='d-grid w-100 mb-2 bg-secondary'>
          <div className='rounded'>
            <ChatTextBox 
              userData={userData} 
              bid={selectedItem} 
              handleUpdateResult={handleUpdateResult} 
              renderView={renderView} 
            />
          </div>
        </div>
      </div>
    ) : null;
  };
  

  return (
    <div className="container mt-5">
      <div className='mb-4'>
        <p className='text-primary text-2xl font-bold text-justify'>Bidding Room</p>
      </div>
      <div className="row">
        {/* Left side: Buttons dynamically generated from data */}
        <div className="col-md-3">
          <div className="d-flex flex-column">
          {
            data.length > 0 ? (
              (() => {
                const seen = new Set(); // Initialize seen set
                return [...new Map(data.map(item => [item._id, item])).values()]
                  .filter((item) => {
                    // Create unique combinations of senderId, receiverId, and swapId
                    const uniqueKey1 = `${item.senderId._id}-${item.receiverId._id}-${item.swapId._id}`;
                    const uniqueKey2 = `${item.receiverId._id}-${item.senderId._id}-${item.swapId._id}`;
                    const uniqueKey3 = `${item.bidPartner._id}-${item.bidPartner._id}-${item.swapId._id}`;

                    // Check if none of the unique keys have been seen
                    if (!seen.has(uniqueKey1) && !seen.has(uniqueKey2) && !seen.has(uniqueKey3)) {
                      seen.add(uniqueKey1); // Mark all the keys as seen
                      seen.add(uniqueKey2);
                      seen.add(uniqueKey3);
                      return true; // Keep the item
                    }
                    return false; // If any of the keys have been seen, filter out the item
                  })
                  .filter((item) => 
                    (item.senderId.name !== item.receiverId.name && 
                    (item.receiverId._id === userData._id || item.senderId._id === userData._id))
                  )
                  .map(item => (
                    deletedBid !== item._id ? (
                    <button
                      key={item._id}
                      className={`btn mb-2 ${currentView === item._id ? 'active btn-dark' : 'btn-primary'}`}
                      onClick={() => handleChatView(item)}
                    >
                      {
                        item.swapId.user._id === userData._id ? (
                          <div>{item.bidPartner.name}</div>
                        ) : (
                          <div>{item.swapId.user.name}</div>
                        )
                      }
                      <small>({item.swapId.swapName})</small>
                    </button>
                    ):(
                      <span></span>
                    )
                  ));
              })()
            ) : data && data === 0?(
              <div className='h-100 text-xxl mt-10'>
                <center><b>List Empty...</b><br/><b className="fs-4 text-dark fw-normal">You are currently not in any bids.<br/><br/>You can start a Bid by making an offer to a swap Proposal. <span className='text-primary cursor-pointer' onClick={()=>renderView("view_offers")}>Click Here</span> to get started</b></center><br/>
              </div>
            ):(
              <div className='h-100 text-xxl mt-10'>
                <center><b className="fs-4">Loading List..</b></center>
                <img src={loader1} alt='loader' />
              </div>
            )
          }


          </div>
        </div>

        {/* Right side: Display the content based on the selected view */}
        <div className="col-md-9">
          <div className="view-content">
            {getViewComponent()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BidPageView;
