import React from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const GoogleLoginButton = ({ text }) => {
    const navigate = useNavigate();

    const responseGoogle = async (response) => {
        const idToken = response.credential; // Use credential directly as idToken

        try {
            // Send idToken to your backend
            const res = await axios.post('https://www.swaptime.co/swaptime_server/user/loginwithgoogle', {
                idToken: idToken, // Pass the token correctly
            }, {
                headers: { 'Content-Type': 'application/json' }
            });

            if (res && res.data && res.status === 200) {
                const token = res.data.token; // Assuming token is returned from the backend
                localStorage.setItem('token', token);
                navigate('/dashboard'); // Redirect to dashboard on successful login
            } else {
                console.error('Something went wrong', res.data.message);
            }
        } catch (error) {
            console.error('Login failed:', error.response ? error.response.data.message : error.message);
        }
    };

    const onFailure = (error) => {
        console.error('Login failed:', error);
    };

    return (
        <GoogleOAuthProvider clientId="31429622162-i0qg2g034fjlm2r49deqb9f4bntcoj3u.apps.googleusercontent.com">
            <GoogleLogin
                onSuccess={responseGoogle}
                onError={onFailure}
                text={text} // Render the button with the correct text
                useOneTap // Enable one-tap login
            />
        </GoogleOAuthProvider>
    );
};

export default GoogleLoginButton;
