import React, { useEffect, useState } from 'react'
import defaultImage from '../../assets/images/userImage/defaultUserImage.png'
import LogoutButton from '../auth/Logout'
import image1 from "../../assets/images/client-1.jpg"
import image2 from "../../assets/images/client-2.jpg"
import image3 from "../../assets/images/images-2.jpeg"
import image4 from "../../assets/images/AdobeStock_829005055.jpeg"
import useFetch from '../custom_hooks/useFetch'
import loader1 from '../../assets/images/03-42-11-849_512.gif'

const DashboardTopbar = ({userData, onProfileUpdate, renderView}) => {
    const [bidData, setBidData] = useState("")
    let link = defaultImage 
    if(userData.profileImage){
        link = userData.profileImage
    }
    const [profileImagePreview, setProfileImagePreview] = useState(link || defaultImage); 
    useEffect(() => {
        link = userData.profileImage
        setProfileImagePreview(link || defaultImage)
        onProfileUpdate()
    }, [userData]);

    const {result, isResultPending, resultError} = useFetch("https://www.swaptime.co/swaptime_server/bid/getBids");
    /*if(!isResultPending){
        console.log("The bids: ", result)
        console.log("The bid error: ", resultError)
    }*/

    const handleBidPageAlert = () =>{
        alert("Bid page is currently under construction")
    }

    function humanDate(isoDate) {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const date = new Date(isoDate);
        return date.toLocaleDateString(undefined, options);
      }
    return ( 
        <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">

            {/*<!-- Sidebar Toggle (Topbar) -->*/}
            <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                <i className="fa fa-bars"></i>
            </button>

            {/*<!-- Topbar Search -->*/}
            <form
                className="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
                <div className="input-group">
                    <input type="text" className="form-control bg-light border-0 small" placeholder="Find Swaps..."
                        aria-label="Search" aria-describedby="basic-addon2"/>
                    <div className="input-group-append">
                        <button onClick={()=>{renderView('view_offers')}} className="btn btn-primary" type="button">
                            <i className="fas fa-search fa-sm"></i>
                        </button>
                    </div>
                </div>
            </form>

            {/*<!-- Topbar Navbar -->*/}
            <ul className="navbar-nav ml-auto">

                {/*<!-- Nav Item - Search Dropdown (Visible Only XS) -->*/}
                <li className="nav-item dropdown no-arrow d-sm-none">
                    <a className="nav-link dropdown-toggle" href="#" id="searchDropdown" role="button"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="fas fa-search fa-fw"></i>
                    </a>
                    {/*<!-- Dropdown - Messages -->*/}
                    <div className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in"
                        aria-labelledby="searchDropdown">
                        <form className="form-inline mr-auto w-100 navbar-search">
                            <div className="input-group">
                                <input type="text" className="form-control bg-light border-0 small"
                                    placeholder="Search for..." aria-label="Search"
                                    aria-describedby="basic-addon2"/>
                                <div className="input-group-append">
                                    <button className="btn btn-primary" type="button">
                                        <i className="fas fa-search fa-sm"></i>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </li>

                {/*<!-- Nav Item - Alerts -->*/}
                <li className="nav-item dropdown no-arrow mx-1">
                    <a className="nav-link dropdown-toggle" href="#" id="alertsDropdown" role="button"
                        data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="fas fa-bell fa-fw"></i>
                        {/*<!-- Counter - Alerts -->*/}
                        {
                            isResultPending ? (
                                <div className='h-100 text-xxl mt-10'>
                                <center><span>Loading Notifications</span></center><br/>
                                <img src={loader1} alt='loader' />
                                </div>
                            ) : result.bids && result.bids.length > 0 ? (
                                (() => {
                                // Filter the bids based on the user ID and senderType
                                const filteredBids = result.bids.filter((item) => 
                                    (((item.swapId.user._id === userData._id && item.senderType !== "user") ||
                                    (item.bidPartner._id === userData._id && item.senderType !== "bidPartner")) && item.seenStatus === false)
                                );
                                
                                // Count the filtered bids
                                const count = filteredBids.length;

                                // Render the badge with the count
                                return (
                                    <span className="badge badge-danger badge-counter">
                                    {count > 0 ? (count > 3 ? '3+' : count) : ''}
                                    </span>
                                );
                                })()
                            ) : (
                                <span className="badge badge-danger badge-counter"></span>
                            )
                        }

                    </a>
                    {/*<!-- Dropdown - Alerts -->*/}
                    <div className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
                        aria-labelledby="alertsDropdown" style={{maxHeight:"300px", overflow:"auto"}}>
                        <h6 className="dropdown-header">
                            Alerts Center
                        </h6>
                        {
                            isResultPending ? (
                                <div className='h-100 text-xxl mt-10'>
                                    <center><span>Loading Notifications</span></center><br/><img src={loader1} alt='loader' />
                                </div>
                            ) : result.bids && result.bids.length > 0 ? (
                                result.bids
                                .filter((item) => ((((item.swapId.user._id === userData._id) && (item.senderType!== "user")) || ((item.bidPartner._id === userData._id) && (item.senderType!== "bidPartner"))))) // Filter results based on user ID
                                .map((filteredItem) => (

                                    <a className="dropdown-item d-flex align-items-center" onClick={()=>{renderView('bid_page')}}>
                                        {/* onClick={handleBidPageAlert}*/}
                                        <div className="mr-3">
                                            <div className="icon-circle bg-secondary">
                                            <img className="rounded-circle" src={filteredItem.senderId.profileImage} style={{height:"45px", width:"50px"}} alt="..."/>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="small text-gray-500">{humanDate(filteredItem.createdAt)}</div>
                                            {
                                            
                                                <span className={`${filteredItem.seenStatus === false ? 'fw-bold':'fw-light'}`}>{filteredItem.senderId.name} Just responded to your swap Offer!</span>
                                                
                                            }
                                            
                                        </div>
                                    </a>
                                ))
                            ):(
                                <div>No Notifications </div>
                            )
                        }
                    </div>
                </li>

                {/*<!-- Nav Item - Messages -->*/}
                <li className="nav-item dropdown no-arrow mx-1">
                    <a className="nav-link dropdown-toggle" href="#" id="messagesDropdown" role="button"
                        data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {/*<i className="fas fa-envelope fa-fw"></i>*/}
                        {/*<!-- Counter - Messages -->
                        <span className="badge badge-danger badge-counter">7</span>*/}
                    </a>
                    {/*<!-- Dropdown - Messages -->*/}
                    <div className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
                        aria-labelledby="messagesDropdown">
                        <h6 className="dropdown-header">
                            Message Center
                        </h6>
                        <a className="dropdown-item d-flex align-items-center" href="#">
                            <div className="dropdown-list-image mr-3">
                                <img className="rounded-circle" src={image1}
                                    alt="..."/>
                                <div className="status-indicator bg-success"></div>
                            </div>
                            <div className="font-weight-bold">
                                <div className="text-truncate">Hi there! I am interested in your swap offer, Let's talk.</div>
                                <div className="small text-gray-500">Alice Rewan · 58m</div>
                            </div>
                        </a>
                        <a className="dropdown-item d-flex align-items-center" href="#">
                            <div className="dropdown-list-image mr-3">
                                <img className="rounded-circle" src={image2}
                                    alt="..."/>
                                <div className="status-indicator"></div>
                            </div>
                            <div>
                                <div className="text-truncate">I have delivered the photos as per your request</div>
                                <div className="small text-gray-500">Jae Chun · 1d</div>
                            </div>
                        </a>
                        <a className="dropdown-item d-flex align-items-center" href="#">
                            <div className="dropdown-list-image mr-3">
                                <img className="rounded-circle" src={image3}
                                    alt="..."/>
                                <div className="status-indicator bg-warning"></div>
                            </div>
                            <div>
                                <div className="text-truncate">Last month's report looks great, I am very happy with
                                    the progress so far, keep up the good work!</div>
                                <div className="small text-gray-500">Morgan Alvarez · 2d</div>
                            </div>
                        </a>
                        <a className="dropdown-item d-flex align-items-center" href="#">
                            <div className="dropdown-list-image mr-3">
                                <img className="rounded-circle" src={image4}
                                    alt="..."/>
                                <div className="status-indicator bg-success"></div>
                            </div>
                            <div>
                                <div className="text-truncate">let's make faster progress. We are gettig there...</div>
                                <div className="small text-gray-500">Charles · 2w</div>
                            </div>
                        </a>
                        <a className="dropdown-item text-center small text-gray-500" href="#">Read More Messages</a>
                    </div>
                </li>

                <div className="topbar-divider d-none d-sm-block"></div>

                {/*<!-- Nav Item - User Information -->*/}
                <li className="nav-item dropdown no-arrow">
                    <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button"
                        data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span className="mr-2 d-none d-lg-inline text-gray-600 small">{userData.name}</span>
                        <img className="img-profile rounded-circle"
                            src={profileImagePreview}/>
                    </a>
                    {/*<!-- Dropdown - User Information -->*/}
                    <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                        aria-labelledby="userDropdown">
                        <a onClick={()=>{renderView('onboard')}} className="dropdown-item" href="#">
                            <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                            Profile
                        </a>
                        {/*<a className="dropdown-item" href="#">
                            <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                            Settings
                        </a>
                        <a className="dropdown-item" href="#">
                            <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
                            Activity Log
                        </a>*/}
                        <div className="dropdown-divider"></div>
                        <center><LogoutButton/></center>
                    </div>
                </li>

            </ul>

        </nav>
     );
}
 
export default DashboardTopbar;