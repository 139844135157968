import React, { useEffect, useState } from 'react';
import useFetch from '../../custom_hooks/useFetch';
import loader1 from '../../../assets/images/03-42-11-849_512.gif'
import defaultImage from '../../../assets/images/userImage/defaultUserImage.png'

const ViewSwapList = ({userData, renderView}) =>{
    const {result, isResultPending, resultError} = useFetch("https://www.swaptime.co/swaptime_server/swap/getSwapOffers");

    return(
        <>
            
            {
                isResultPending ? (
                    <div className='h-100 text-xxl mt-10'>
                        <center><span>Loading Swap Requests</span></center><br/><img src={loader1} alt='loader' />
                    </div>
                ) : result.swapOffers && result.swapOffers.length > 0 ? (
                    result.swapOffers
                    .filter((item) => item.user._id !== userData._id) // Filter results based on user ID
                    .map((filteredItem) => (
                        <div className='row bg-light border mb-1 rounded p-2 cursor-pointer' onClick={()=>{renderView('view_offers')}}>
                            <div className='col-md-4'>
                                <center><img src={filteredItem.user.profileImage?filteredItem.user.profileImage:defaultImage} alt="User Image" className="img-fluid mb-3 rounded" style={{maxHeight:"200px",}} /></center>
                            </div>
                            <div className='col-md-8'>
                                <span><b>Name:</b> {filteredItem.user.name}</span><br/>
                                <span><b>Location:</b> {filteredItem.user.country.name}</span><br/>
                                <span><b>Skill to Swap: </b>{filteredItem.swapSkill.name}</span><br/>
                                <span><b>Skill Needed: </b>{filteredItem.swapNeedSkill.name}</span><br/>
                                <span><b>Rating: </b>4.6/5</span>
                            </div>
                        </div>
                    ))
                ) : (
                    <div>No Results Found</div>
                )
            }
        </>
    )
}

export default ViewSwapList
