import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../assets/images/logo.svg'
import loader1 from '../../assets/images/03-42-11-849_512.gif'
import Benefit from './SwaptimeBenefitSlide';
import AuthSidebar from './Sidebar';
import '../../assets/styles/font_style.css';
import google from '../../assets/images/93u2c.png'
import axios from 'axios';
import useCheckInternet from '../generalComponents/checkOnline';
import useFetch from '../custom_hooks/useFetch'
import GoogleLoginButton from './GoogleLoginButton';


const RegForm = () => {
    const [currentStep,setCurrentStep] = useState(1)
    const [fname, setFName] = useState('')
    const [lname, setLName] = useState('')
    const [email, setEmail] = useState('')
    const [country, setCountry] = useState('')
    //const [skillPossessed, setSkillPossessed] = useState('')
    //const [skillRequired, setSkillRequired] = useState('')
    //const [proofOfWork, setProofOfWork] = useState('')
    const [password, setPassword] = useState('')
    const [cPassword, setCPassword] = useState('')
    const [isPending, setIsPending] = useState(false)
    const [error, setError] = useState({});
    const [serverError, setServerError] = useState(null);
    const [hideTop, setHideTop] = useState(false)
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const navigate = useNavigate()

    const { result, isResultPending, resultError } = useFetch('https://www.swaptime.co/swaptime_server/country/getCountry');
    

    useEffect(() => {
      const handleResize = () => {
        setScreenWidth(window.innerWidth);
      };
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    const handleInfoClick = () =>{
        alert("Backend Currently in Development...")
    }
    const handleNext = () =>{
        const hasErrors = Object.values(error).some((error) => error !== '');
        if (hasErrors) {
            return; 
        }
        if (currentStep >= 1 && currentStep < 3) {
            // Check if current step fields are filled before proceeding
            switch (currentStep) {
                case 1:
                    if (!fname || !lname || !email || /*!phoneNumber ||*/ !country) {
                        return; // Don't proceed if fields are empty
                    }
                    break;
                /*case 2:
                    if (!skillPossessed || !skillRequired || !proofOfWork) {
                        return; // Don't proceed if fields are empty
                    }
                    break;*/
                default:
                    break;
            }
            setCurrentStep(currentStep + 1);
        }
    }
    const validateField = (field, value) => {
        let error = '';
        if(value !== undefined){
            if (field === 'fname') {
                if (value.trim() === '') {
                    error = 'First Name is required';
                }
            } if (field === 'lname') {
                if (value.trim() === '') {
                    error = 'Last Name is required';
                }
            } else if (field === 'email') {
                // Basic email validation (adjust as needed)
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (!emailRegex.test(value)) {
                    error = 'Invalid email address';
                }
            } else if (field === 'country') {
                if (value.trim() === '') {
                    error = 'Country is required';
                }
            } else if (field === 'password') {
                const passwordRegex = /^(?=.*[A-Z]).{8,}$/;;
                if (!passwordRegex.test(value)) {
                error = 'Password must be at least 8 characters long and contain at least one uppercase letter';
                }
            } else if (field === 'cPassword') {
                if(value !=='undefined'){
                    if(value[0].trim() !== value[1].trim()){
                        error = "Password does not match"
                    }
                }
            }
        }
        setError((prevErrors) => ({ ...prevErrors, [field]: error }));
    };
    
    const isOnline = useCheckInternet();
    const handleFormSubmit = async (e) =>{
        e.preventDefault()
        if (!isOnline) {
            setServerError('You are offline. Please check your internet connection and try again.');
            return;
        }
        validateField('fname',fname)
        validateField('lname',lname)
        validateField('email',email)
        validateField('country',country)
        validateField('password',password)
        const hasErrors = Object.values(error).some((error) => error !== '');
        if (hasErrors) {
        return;
        }
        setIsPending(true)
        const name = fname + " " + lname
        const data = {name, email, country, password, cPassword}
        //alert(JSON.stringify(data))
        try {
            const response = await axios.post('https://www.swaptime.co/swaptime_server/user/addUser', JSON.stringify(data), {
            headers: { 'Content-Type': 'application/json' },
            });

            setIsPending(false);
            if (response.status === 200) {
                const token = response.data.token; // Assuming token is returned from backend
                localStorage.setItem('token', token);
                //console.log(response.message)
                //console.log('User Registered');
                setHideTop(true) 
                setServerError(null)
                setCurrentStep(currentStep + 1);
            }else{
                setServerError(response.data.message)
            }
        } catch (error) {
            setIsPending(false);
            setServerError(error)
            console.error(error)
        }

    }

   

    const renderStep = () => {
        switch(currentStep){
            case 1:
                return(
                    <>
                        <div className='mb-2'>
                            <input 
                                type="text" 
                                id="fname"
                                value={fname}
                                onChange={(e) => {
                                    setFName(e.target.value);
                                    validateField('fname', e.target.value);
                                }}
                                onBlur={() => validateField('fname', fname)}
                                className="rounded-lg px-6 py-3 w-full" 
                                placeholder="First Name"
                            />
                            {error.fname && <p style={{float:"left"}} className="text-danger mt-1 mb-3 ml-2">{error.fname}</p>}
                        </div>
                        <div className='mb-2'>
                            <input 
                                type="text" 
                                id="lname"
                                value={lname}
                                onChange={(e) => {
                                    setLName(e.target.value);
                                    validateField('lname', e.target.value);
                                }}
                                onBlur={() => validateField('lname', lname)}
                                className="rounded-lg px-6 py-3 w-full" 
                                placeholder="Last Name"
                            />
                            {error.lname && <p style={{float:"left"}} className="text-danger mt-1 mb-3 ml-2">{error.lname}</p>}
                        </div>
                        <div className='mb-2'>
                            <input 
                                type="email" 
                                id="email"
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                    validateField('email', e.target.value);
                                }}
                                onBlur={() => validateField('email', email)}
                                className="rounded-lg px-6 py-3 w-full" 
                                placeholder="Email Address"
                            />
                            {error.email && <p style={{float:"left"}} className="text-danger mt-1 mb-3 ml-2">{error.email}</p>}
                        </div>
                        <div className='mb-2'>
                            <select 
                                name="country" 
                                className="rounded-lg px-6 py-3 w-full text-gray-500" 
                                value={country} 
                                onChange={(e)=>{setCountry(e.target.value)}} 
                                onBlur={() => validateField('country', country)}
                                id="country"
                                placeholder="Country of Residence"
                            >
                            {/* Conditional rendering of options */}
                            {isResultPending ? (
                                <option>Loading...</option>
                            ) : result.country && result.country.length > 0 ? (
                                result.country.map((item) => (
                                    <option key={item._id} value={item._id}>
                                        {item.name}
                                    </option>
                                ))
                            ) : (
                                <option value="">No countries available</option>
                            )}
                            
                            </select>
                            {error.country && <p style={{float:"left"}} className="text-danger mt-1 mb-3 ml-2">{error.country}</p>}
                        </div>
                        <div className='mb-2'>
                            <input 
                                type="password" 
                                id="password"
                                value={password}
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                    validateField('password', e.target.value);
                                }}
                                onBlur={() => validateField('password', password)}
                                className="rounded-lg px-6 py-3 w-full" 
                                placeholder="Password"
                            />
                            {error.password && <p style={{float:"left"}} className="text-danger mt-1 mb-3 ml-2">{error.password}</p>}
                        </div>
                        <div className='mb-2'>
                            <input 
                                type="password" 
                                id="cpassword"
                                value={cPassword}
                                onChange={(e) => {
                                    setCPassword(e.target.value);
                                    validateField('cPassword', [password,e.target.value]);
                                }}
                                onBlur={() => validateField('cPassword', [password,cPassword])}
                                className="rounded-lg px-6 py-3 w-full" 
                                placeholder="Confirm Password"
                            />
                            {error.cPassword && <p style={{float:"left"}} className="text-danger mt-1 mb-3 ml-2">{error.cPassword}</p>}
                        </div>
                    </>
                );
            case 2:
                return(
                    <>
                        <div className='pt-10 mt-5 bg-warning rounded-lg p-4 pt-2' style={{color:"black"}}><br/>
                            <h2 className='font-bold  text-4xl mb-2'>Welcome {fname}</h2>
                            <p className=' text-lg mb-2'>
                                You are now a part of Swaptime Community.<br></br>
                                Please complete your profile so that you can build your 
                                network with people who have the skills you need
                            </p>
                            <p className=' text-md mt-5 mb-5'>
                                <Link to="/dashboard" className='btn btn-primary d-block py-3'>Complete Your Profile</Link>
                            </p>
                        </div>
                    </>
                )
            default:
                return null;
        }

    }

    const isNextDisabled = () => {
        const hasErrors = Object.values(error).some((error) => error !== '');
        if(hasErrors){
            return true;
        }else{
            return false;
        }
    };
    let gridCol;
    if(screenWidth <= '780'){
        gridCol = 'grid-cols-1';
    }else{
        gridCol = 'grid-cols-2';
    }
    return (
        <section className="" style={{fontFamily:"Helvetica", backgroundColor:"#fff"}}>
            <div className="row pb-5">
                <AuthSidebar screenSize={screenWidth}/>
                <div className='col-md-6 pb-5'>
                    <center>
                        {isPending && <div className='h-screen fs-1 mt-10'><img src={loader1} alt='loader' /><br/>Loading...</div>}
                        {
                            !isPending && 
                            <div style={{width:'80%'}}>
                                {
                                    !hideTop &&
                                    <center>
                                        <Link to="/"><h3 className='w-25 mt-5'><img src={logo} alt=''/></h3></Link>
                                        <p className='my-3 text-md font-mono h-50'>
                                            <Benefit bgColor="text-slate-700" textColor="black"/>
                                        </p>
                                    </center>
                                }
                                
                                <form onSubmit={handleFormSubmit} >
                                    {serverError && <div className=" alert alert-danger px-4 py-2 rounded-md">Registration failed. Please try again later.{serverError.message}</div>}
                                    {renderStep()}
                                    {!hideTop && currentStep ===1 && (
                                        <>
                                        <div className='mb-2 mt-1 d-grid text-white'><br/>
                                            <button type="button"  onClick={handleFormSubmit} className='btnpx-3' style={{color:"#fff",backgroundColor:"#FF7518"}}>Create Your Account</button>
                                        </div>
                                        
                                        <center className='text-gray-600 my-6'>
                                            -------- OR -------

                                            <div className='mt-4 mb-2'>
                                                <GoogleLoginButton text="Sign Up With Google"/>
                                            </div>
                                        </center>
                                        </>)
                                    }
                                    {/*currentStep ===2 && (
                                        <div className='mb-2'>
                                            <button type="button" onClick={handleLogin}  className='border-1 border-solid border-gray-800 bg-orange-200 hover:bg-zinc-900 hover:text-white text-white w-full'>Continue</button>
                                        </div>)*/
                                    }
                                </form>
                                {
                                    !hideTop &&     
                                    <div className='mb-5'>
                                        Already have an account? <Link to="/login" className='text-primary'>Login here</Link><br/><br/>
                                    </div>
                                }           
                            </div>
                        }
                    </center>
                </div>
            </div>
        </section>
    );
}

export default RegForm;

