import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import hero_image from '../../assets/images/AdobeStock_508035363.jpeg';
import hero_image2 from '../../assets/images/InShot_20240815_165910230.jpg'
import '../../assets/styles/font_style.css';

const Hero = () => {
    const [isHovered, setIsHovered] = useState(false)
    const style = {
        brandBrownButton:{
            backgroundColor: isHovered ? '#A0522D' : '#FF7518',

        }
    }
    return (
        <section className="d-flex align-items-center justify-content-center vh-100" style={{ backgroundImage: `url(${hero_image2})`, backgroundSize: 'cover', backgroundPosition: 'center', fontFamily:"Helvetica", backgroundColor:"#fff"}}>
            <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap" rel="stylesheet"/>

            <div className=' d-block d-md-none d-lg-none col-md-12'>
                <div className="container text-white bg-dark p-4 rounded mt-5 h-25" style={{width:"95%",opacity:"90%", minWidth:"250px"}}>
                    <h3 className="display-4 mb-4 fs-3" style={{fontFamily:"Roboto", fontWeight:"lighter"}}>Trade skills you have for the skills you need</h3>
                    <p className="lead mb-4 fs-6">
                        Swaptime is a global online marketplace using a barter system, where entrepreneurs and small business owners can swap skills they have for skills they need.
                    </p>
                    <Link to="/register" className="btn btn-warning btn-lg" style={style.brandBrownButton} onMouseEnter={()=>{setIsHovered(true)}} onMouseLeave={()=>{setIsHovered(false)}}>
                        Join the Community
                    </Link>
                </div>
            </div>
            <div className='d-none d-md-block d-lg-block col-md-6'>
                <div className="container p-4 rounded mt-5 h-25" style={{width:"90%",opacity:"100%", color:"black"}}>
                    <h1 className="display-1 fw-bold mb-4 fs-1" style={{fontFamily:"Helvetica", fontWeight:"lighter"}}>Trade skills you have for the skills you need</h1>
                    <p className="lead mb-4 fs-5 text-dark" style={{textShadow:"1px 2px 3px 2px black"}}>
                        Swaptime is a global online marketplace using a barter system, where entrepreneurs and small business owners can swap skills they have for skills they need.
                    </p>
                    <Link to="/register" className="btn btn-warning btn-lg" style={style.brandBrownButton} onMouseEnter={()=>{setIsHovered(true)}} onMouseLeave={()=>{setIsHovered(false)}}>
                        Join the Community
                    </Link>
                </div>
            </div>
            <div className=' d-none d-md-block d-lg-block col-md-6'>

            </div>
        </section>
    );
}

export default Hero;
