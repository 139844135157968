import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import image1 from '../../assets/images/Entrepreneurs.png'
import image2 from '../../assets/images/Creatives.png'
import image3 from '../../assets/images/SBO.png'
import image4 from '../../assets/images/Start-ups.png'
import image5 from '../../assets/images/Image-1.svg'
import client1 from '../../assets/images/client-1.jpg'
import client2 from '../../assets/images/client-2.jpg'
import client3 from '../../assets/images/professional-img.png'
import client4 from '../../assets/images/about-img.jpg'
import client5 from '../../assets/images/images-2.jpeg'
import logo from '../../assets/images/logo.svg'
import how_logo from '../../assets/images/A New Design - Made with PosterMyWall-6.jpg'
import how_support_logo from '../../assets/images/Firefly_142a054d-b2b5-4663-a70b-e2edc6a0aaeb.jpeg'
import bg from "../../assets/images/AdobeStock_470378333.jpeg"
import '../../assets/styles/font_style.css';


import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import BootstrapCard from '../generalComponents/BootstrapCard';


const Body = () => {
    const [isHovered, setIsHovered] = useState(false)
    const style = {
        brandBrownButton:{
            backgroundColor: isHovered ? '#A0522D' : '#FF7518',

        }
    }
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
    };

    const settings2 = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
    };

  return (
    <div style={{fontFamily:"Roboto", backgroundColor:"#fff"}}>
        <section className="p-5" style={{zIndex:1, fontFamily:"Maison Neue", backgroundImage:`url(${bg})`, backgroundSize:"cover", backgroundPosition: 'center'}}>
            <center>
                <div className='card p-4 rounded-lg shadow-lg' style={{width:"50%", minWidth:"250px"}}>
                    <span className='fs-3 font-bold'>Get Instant Connections</span>
                    <p>Find people who need the skill you have and are willing to offer the skill you need.</p><br/>
                    <Link to="/register" className="btn btn-warning text-white font-medium rounded-lg text-xxl px-5 py-3 text-center " style={style.brandBrownButton} onMouseEnter={()=>{setIsHovered(true)}} onMouseLeave={()=>{setIsHovered(false)}}>Join the Community</Link>
                </div>
            </center>
        </section>
        <hr/>
        <section className="mt-1 p-1" style={{zIndex:1, fontFamily:"Helvetica", backgroundColor:"#fff"}}>
            <center>
                <div className=' row ' style={{width:"98%"}}>
                    <div className='col-md-7'>
                        <img src={how_logo} className='w-full' alt=''  style={{width:'500px'}}/>
                    </div>
                    <div className='col-md-5 text-start' style={{color:"black"}}>
                        <p className='mt-5 mb-8 font-bold text-3xl' style={{fontFamily:"Roboto"}} >The Swaptime Process</p>
                        <p className='text-xl'>Its Simple. Sign up. Complete your profile. Request a swap with your skills and choose who you want to work with for free.</p>
                    </div>  
                </div>  
            </center>
        </section>
        <hr/>
        <section className="py-7 px-4" style={{zIndex:1, fontFamily:"Roboto", backgroundColor:"#fff"}}>
            <center className='mt-5 mb-8 font-bold text-3xl' style={{fontFamily:"Roboto", color:"black"}} >Who is Swaptime for?</center><br></br>
            <Slider {...settings}>
                <BootstrapCard 
                    image={image1} 
                    title="Entrepreneurs" 
                    description="Entrepreneurs and small business owners who need various services to grow and scale their operations without incurring high costs."
                /> 
                <BootstrapCard 
                    image={image2} 
                    title="Freelancers and Professionals" 
                    description="Individuals looking to trade their expertise for services they need, enhancing their professional growth while saving money."
                />  
                <BootstrapCard 
                    image={image3} 
                    title="Creative Individuals" 
                    description="Artists, designers, photographers, and other creative talents who want to exchange their skills or creative products for complementary services or goods."
                />  
                <BootstrapCard 
                    image={image4} 
                    title="Product Owners" 
                    description="Individuals and businesses with products they wish to trade, seeking to swap these items for other valuable products or services."
                />    
                <BootstrapCard 
                    image={image1} 
                    title="Anyone with Skills or Products to Offer" 
                    description="If you have a skill or product and need something in return, Swaptime provides the perfect platform for facilitating these exchanges."
                />   
            </Slider>
        </section>
        <section className="bg-gray-50 dark:bg-gray-800 mt-4" style={{fontFamily:"Maison Neue"}}>
            <div className="max-w-screen-xl px-4 py-8 mx-auto text-center lg:py-24 lg:px-6">
            <Slider {...settings2}>
                <figure className="max-w-screen-md mx-auto">
                    <svg className="h-12 mx-auto mb-3 text-gray-400 dark:text-gray-600" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z" fill="currentColor"/>
                    </svg> 
                    <blockquote>
                        <p className="text-xl font-medium text-gray-900 md:text-2xl dark:text-white">"Swaptime has been a game-changer for my small business. I swapped my graphic design skills for marketing expertise, which helped me launch a successful campaign without any extra costs. The community support is incredible!"</p>
                    </blockquote>
                    <figcaption className="flex items-center justify-center mt-6 space-x-3">
                        <img className="w-6 h-6 rounded-full" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/michael-gouch.png" alt="profile picture"/>
                        <div className="flex items-center divide-x-2 divide-gray-500 dark:divide-gray-700">
                            <div className="pr-3 font-medium text-gray-900 dark:text-white">Anita</div>
                            <div className="pl-3 text-sm font-light text-gray-500 dark:text-gray-400">Small Business Owner</div>
                        </div>
                    </figcaption>
                </figure>
                <figure className="max-w-screen-md mx-auto">
                    <svg className="h-12 mx-auto mb-3 text-gray-400 dark:text-gray-600" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z" fill="currentColor"/>
                    </svg> 
                    <blockquote>
                        <p className="text-xl font-medium text-gray-900 md:text-2xl dark:text-white">"As a freelance web developer, finding affordable design services was always a challenge. Through Swaptime, I was able to trade my coding skills for graphic design, saving me money and enhancing my portfolio."</p>
                    </blockquote>
                    <figcaption className="flex items-center justify-center mt-6 space-x-3">
                        <img className="w-6 h-6 rounded-full" src={client1} alt="profile picture"/>
                        <div className="flex items-center divide-x-2 divide-gray-500 dark:divide-gray-700">
                            <div className="pr-3 font-medium text-gray-900 dark:text-white">Godwin</div>
                            <div className="pl-3 text-sm font-light text-gray-500 dark:text-gray-400">Freelance Web Developer</div>
                        </div>
                    </figcaption>
                </figure>
                <figure className="max-w-screen-md mx-auto">
                    <svg className="h-12 mx-auto mb-3 text-gray-400 dark:text-gray-600" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z" fill="currentColor"/>
                    </svg> 
                    <blockquote>
                        <p className="text-xl font-medium text-gray-900 md:text-2xl dark:text-white">"Starting a business on a tight budget seemed impossible until I found Swaptime. By exchanging my accounting services for legal advice, I was able to navigate the complexities of starting my company without breaking the bank."</p>
                    </blockquote>
                    <figcaption className="flex items-center justify-center mt-6 space-x-3">
                        <img className="w-6 h-6 rounded-full" src={client4} alt="profile picture"/>
                        <div className="flex items-center divide-x-2 divide-gray-500 dark:divide-gray-700">
                            <div className="pr-3 font-medium text-gray-900 dark:text-white">Emily</div>
                            <div className="pl-3 text-sm font-light text-gray-500 dark:text-gray-400">Startup Founder</div>
                        </div>
                    </figcaption>
                </figure>
                <figure className="max-w-screen-md mx-auto">
                    <svg className="h-12 mx-auto mb-3 text-gray-400 dark:text-gray-600" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z" fill="currentColor"/>
                    </svg> 
                    <blockquote>
                        <p className="text-xl font-medium text-gray-900 md:text-2xl dark:text-white">"Swaptime isn't just for businesses. I used my social media management skills to get personal training sessions. It's a fantastic way to learn new skills and meet talented people."</p>
                    </blockquote>
                    <figcaption className="flex items-center justify-center mt-6 space-x-3">
                        <img className="w-6 h-6 rounded-full" src={client3} alt="profile picture"/>
                        <div className="flex items-center divide-x-2 divide-gray-500 dark:divide-gray-700">
                            <div className="pr-3 font-medium text-gray-900 dark:text-white">Alex</div>
                            <div className="pl-3 text-sm font-light text-gray-500 dark:text-gray-400">Individual User</div>
                        </div>
                    </figcaption>
                </figure>
                <figure className="max-w-screen-md mx-auto">
                    <svg className="h-12 mx-auto mb-3 text-gray-400 dark:text-gray-600" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z" fill="currentColor"/>
                    </svg> 
                    <blockquote>
                        <p className="text-xl font-medium text-gray-900 md:text-2xl dark:text-white">"What I love about Swaptime is the sense of community. I traded my photography skills for website development and ended up collaborating on multiple projects with the same person. It's a supportive network where everyone benefits."</p>
                    </blockquote>
                    <figcaption className="flex items-center justify-center mt-6 space-x-3">
                        <img className="w-6 h-6 rounded-full" src={client5} alt="profile picture"/>
                        <div className="flex items-center divide-x-2 divide-gray-500 dark:divide-gray-700">
                            <div className="pr-3 font-medium text-gray-900 dark:text-white">Rachel</div>
                            <div className="pl-3 text-sm font-light text-gray-500 dark:text-gray-400">Photographer</div>
                        </div>
                    </figcaption>
                </figure>
            </Slider>
            </div>
            <center><Link to="/register" className="btn btn-warning text-white font-medium rounded-lg text-xxl px-5 py-3 text-center " style={style.brandBrownButton} onMouseEnter={()=>{setIsHovered(true)}} onMouseLeave={()=>{setIsHovered(false)}}>Join the Community</Link></center><br/><br/><br/>
        </section>
        
        <footer className="bg-white dark:bg-gray-800">
            <div className="max-w-screen-xl p-4 py-6 mx-auto lg:py-16 md:p-8 lg:p-10">
                
                <div className="text-center"><br/>
                    <a href="#" className="flex items-center justify-center mb-5 text-2xl font-semibold text-gray-900 dark:text-white">
                        <img src={logo} style={{width:'8%'}} alt="Swaptime Logo" />
                    </a>
                    <span className="block text-sm text-center text-gray-500 dark:text-gray-400">
                        © 2021-2024 Swaptime™.
                    </span>
                    <ul className="flex justify-center mt-5 space-x-5">
                        <li>
                            <a href="#" className="text-gray-500 hover:text-gray-900 dark:hover:text-white dark:text-gray-400">
                                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path fillRule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clipRule="evenodd" /></svg>
                            </a>
                        </li>
                        <li>
                            <a href="#" className="text-gray-500 hover:text-gray-900 dark:hover:text-white dark:text-gray-400">
                                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path fillRule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" clipRule="evenodd" /></svg>
                            </a>
                        </li>
                        <li>
                            <a href="#" className="text-gray-500 hover:text-gray-900 dark:hover:text-white dark:text-gray-400">
                                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" /></svg>
                            </a>
                        </li>
                        <li>
                            <a href="#" className="text-gray-500 hover:text-gray-900 dark:hover:text-white dark:text-gray-400">
                                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path fillRule="evenodd" d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z" clipRule="evenodd" /></svg>
                            </a>
                        </li>
                        <li>
                            <a href="#" className="text-gray-500 hover:text-gray-900 dark:hover:text-white dark:text-gray-400">
                                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path fillRule="evenodd" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10c5.51 0 10-4.48 10-10S17.51 2 12 2zm6.605 4.61a8.502 8.502 0 011.93 5.314c-.281-.054-3.101-.629-5.943-.271-.065-.141-.12-.293-.184-.445a25.416 25.416 0 00-.564-1.236c3.145-1.28 4.577-3.124 4.761-3.362zM12 3.475c2.17 0 4.154.813 5.662 2.148-.152.216-1.443 1.941-4.48 3.08-1.399-2.57-2.95-4.675-3.189-5A8.687 8.687 0 0112 3.475zm-3.633.803a53.896 53.896 0 013.167 4.935c-3.992 1.063-7.517 1.04-7.896 1.04a8.581 8.581 0 014.729-5.975zM3.453 12.01v-.26c.37.01 4.512.065 8.775-1.215.25.477.477.965.694 1.453-.109.033-.228.065-.336.098-4.404 1.42-6.747 5.303-6.942 5.629a8.522 8.522 0 01-2.19-5.705zM12 20.547a8.482 8.482 0 01-5.239-1.8c.152-.315 1.888-3.656 6.703-5.337.022-.01.033-.01.054-.022a35.318 35.318 0 011.823 6.475 8.4 8.4 0 01-3.341.684zm4.761-1.465c-.086-.52-.542-3.015-1.659-6.084 2.679-.423 5.022.271 5.314.369a8.468 8.468 0 01-3.655 5.715z" clipRule="evenodd" /></svg>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </footer>
    <script src="https://unpkg.com/flowbite@1.4.1/dist/flowbite.js"></script>
    </div>

  );
}

export default Body;
