import React from 'react';

const BootstrapCard = ({ image, title, description }) => {
  return (
    <div className="card max-w-sm mx-2 rounded overflow-hidden shadow-lg  font-serif" style={{width:"95%", minHeight:"600px"}}>
      <img src={image} className="card-img-top w-full" alt={title} />
      <div className="card-body px-6 pb-2">
        <h5 className="card-title font-bold text-xl mb-2 fs-3">{title}</h5>
        <p className="card-text text-gray-700 text-base fs-6">{description}</p>
      </div>
    </div>
  );
};

export default BootstrapCard;
