import React from 'react';
import loader1 from '../../assets/images/03-42-11-849_512.gif';
import useFetch from '../custom_hooks/useFetch';
import OfferCardComponent from './utilityComponents/OfferCardComponent';

const ActiveSwaps = ({ userData, onProfileUpdate, renderView=null }) => {
  const {result, isResultPending, resultError} = useFetch("https://www.swaptime.co/swaptime_server/swap/getSwapOffers");
 
  return (
    <>
      <div className='row m-2'>
        <div className='mt-2 mb-3'>
          <p className='text-primary text-4xl font-bold text-justify'>Active Swap Projects</p>
        </div>
        {
            isResultPending ? (
                <div className='h-100 text-xxl mt-10'>
                  <img src={loader1} alt='loader' />
                </div>
            ) : result.swapOffers && result.swapOffers.length > 0 ? (
                result.swapOffers
                .filter((item) => (item.user._id === userData._id || item.partner._id === userData._id) && item.user._id !== item.partner._id) // Filter results based on user ID
                .map((filteredItem) => (
                    <>
                      <OfferCardComponent
                          key={filteredItem._id}  // Ensure each item has a unique key
                          offer={filteredItem}
                          userData = {userData}// Fallback if swapProposal is missing
                          renderView={renderView}
                      />
                    </>
                ))
            ) : (
                <div>No Results Found</div>
            )
        }

      </div>
    </>
  );
};

export default ActiveSwaps;
