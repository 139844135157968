import React, { useState, useEffect, useCallback } from 'react';
import "../assets/fonts/fontawesome-free/css/all.min.css"
import "../assets/css/sb-admin-2.min.css"
import Sidebar from "../components/userDashboard/Sidebar";
import Topbar from "../components/userDashboard/Topbar";
import { useNavigate } from "react-router-dom";
import axios from 'axios'
import loader1 from '../assets/images/03-42-11-849_512.gif'
import OnboardForm from '../components/userDashboard/OnboardForm';
import MainView from '../components/userDashboard/MainView';
import PageUnderCreation from '../components/userDashboard/utilityComponents/PageUnderCreation';
import CreateSwapForm from '../components/userDashboard/CreateSwap';
import ViewSwapRequest from '../components/userDashboard/ViewSwapRequest';
import MySwapOffers from '../components/userDashboard/MyOffers';
import BidPageView from '../components/userDashboard/BidPageView';
import EditSwapForm from '../components/userDashboard/EditSwap';
import SetMilestones from '../components/userDashboard/SetMilestone';
import ActiveSwaps from '../components/userDashboard/ActiveSwap';

const Dashboard = () => {
    const [userData, setUserData] = useState(null);
    const [currentView, setCurrentView] = useState("home")
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [data, setData] = useState(null)
    
    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const navigate = useNavigate();

    const fetchUserData = useCallback(async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                navigate('/login', { state: { serverError: 'Please log in to access the dashboard.' } });
            }
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            };
            const response = await axios.get('https://www.swaptime.co/swaptime_server/user/getUser', config);
            if (!response.status || response.status !== 200) {
                console.log("Your network is unstable")
            }
            setUserData(response.data.user);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                console.log('Session expired. Please log in again.');
            } else {
                console.error('Error fetching user data:', error);
                console.log("Your connection is unstable")
            }
        }
    }, [navigate]);

    useEffect(() => {
        fetchUserData();
    }, [fetchUserData]);

    useEffect(() => {
        if (userData && userData.formFilled === 0) {
            renderView("onboard");
        }
    }, [userData]);

    const renderView = (view, hasData = null) => {
        if (hasData) {
            setData(hasData);
        }
        setCurrentView(view);

        // Push the new view to the browser's history
        window.history.pushState({ view }, "", `#${view}`);
    }

    // Listen for back/forward navigation to update the view
    useEffect(() => {
        const handlePopState = (event) => {
            if (event.state && event.state.view) {
                setCurrentView(event.state.view);
            } else {
                setCurrentView("home");
            }
        };

        window.addEventListener("popstate", handlePopState);

        return () => {
            window.removeEventListener("popstate", handlePopState);
        };
    }, []);

    const getViewComponent = () => {
        switch (currentView) {
            case 'onboard':
                return <OnboardForm userData={userData} onProfileUpdate={fetchUserData} />;
            case 'home':
                return <MainView renderView={renderView} userData={userData} />;
            case 'puc':
                return <PageUnderCreation renderView={renderView} />;
            case 'create_swap':
                return <CreateSwapForm userData={userData} onProfileUpdate={fetchUserData} />;
            case 'edit_swap':
                return <EditSwapForm userData={userData} onProfileUpdate={fetchUserData} swapId={data} renderView={renderView} />;
            case 'set_milestone':
                return <SetMilestones userData={userData} onProfileUpdate={fetchUserData} bid={data} renderView={renderView} />;
            case 'view_offers':
                return <ViewSwapRequest userData={userData} onProfileUpdate={fetchUserData} renderView={renderView} />;
            case 'active_swaps':
                return <ActiveSwaps userData={userData} onProfileUpdate={fetchUserData} renderView={renderView} />;
            case 'my_offers':
                return <MySwapOffers userData={userData} onProfileUpdate={fetchUserData} renderView={renderView} />;
            case 'bid_page':
                return <BidPageView userData={userData} renderView={renderView} />;
            default:
                return <OnboardForm userData={userData} onProfileUpdate={fetchUserData} />;
        }
    };

    return (
        <>
            {!userData && <center><div className='h-screen w-full bg-slate-600 text-dark text-5xl'><img src={loader1} alt='loader' /><br />Loading Your Dashboard...</div></center>}
            {
                userData &&
                <div id="page-top">

                    {/* Page Wrapper */}
                    <div id="wrapper">

                        <Sidebar renderView={renderView} />

                        {/* Content Wrapper */}
                        <div id="content-wrapper" className="d-flex flex-column">

                            {/* Main Content */}
                            <div id="content">

                                {/* Topbar */}
                                <Topbar userData={userData} onProfileUpdate={fetchUserData} renderView={renderView} />
                                {/* End of Topbar */}

                                {/* Begin Page Content */}
                                <div className="container-fluid">

                                    {getViewComponent()}

                                </div>
                                {/* /.container-fluid */}

                            </div>
                            {/* End of Main Content */}

                            {/* Footer */}
                            <footer className="sticky-footer bg-white">
                                <div className="container my-auto">
                                    <div className="copyright text-center my-auto">
                                        <span>Copyright &copy; Swaptime</span>
                                    </div>
                                </div>
                            </footer>
                            {/* End of Footer */}

                        </div>
                        {/* End of Content Wrapper */}

                    </div>
                    {/* End of Page Wrapper */}

                    {/* Scroll to Top Button*/}
                    <a className="scroll-to-top rounded" href="#page-top">
                        <i className="fas fa-angle-up"></i>
                    </a>

                    {/* Logout Modal */}
                    <div className="modal fade" id="logoutModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                        aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Ready to Leave?</h5>
                                    <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body">Select "Logout" below if you are ready to end your current session.</div>
                                <div className="modal-footer">
                                    <button className="btn btn-secondary" type="button" data-dismiss="modal">Cancel</button>
                                    <a className="btn btn-primary" href="login.html">Logout</a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            }
        </>
    );
}

export default Dashboard;
