import React from 'react'
import { Link } from 'react-router-dom'

const PageUnderCreation = ({renderView, userData}) =>{
    return(
        <>
                
                    <div class="text-center">
                        <div class="error mx-auto" data-text="503">503</div>
                        <p class="lead text-gray-800 mb-5">Page Coming Soon...</p>
                        <p class="text-gray-500 mb-0">It looks like you found a glitch in the matrix...</p>
                        <a href="#" onClick={()=>renderView('home')}>&larr; Back to Dashboard</a>
                    </div>
                 
        </>
    )
}

export default PageUnderCreation