import React, { useState } from "react";
import useCheckInternet from "../../generalComponents/checkOnline";
import loader1 from '../../../assets/images/03-42-11-849_512.gif'
import axios from 'axios'


const AcceptOfferForm = ({offer, userData, renderView = null, question=false }) =>{
    const [successMessage, setSuccessMessage] = useState(null)
    const [serverError, setServerError] = useState(null)
    const [error, setError] = useState({})
    const [swapAcceptOffer, setSwapAcceptOffer] = useState("")
    const [isPending, setIsPending] = useState(false)

    const validateField = (field, value) => {
      let error = '';
      if(value !== undefined){
        if (field === 'swapAcceptOffer') {
            if (value.trim() === '') {
                error = 'Field is required';
            }
        }
      }
      setError((prevErrors) => ({ ...prevErrors, [field]: error }));
    }
    
    const isOnline = useCheckInternet();
    const handleSubmit = async (e) => {
        e.preventDefault();  // Prevent the default form submission
        setIsPending(true);  // Set loading state
    
        // Check if online
        if (!isOnline) {
            setServerError('You are offline. Please check your internet connection and try again.');
            setIsPending(false);
            return;
        }
    
        // Validate input fields
        validateField('swapAcceptOffer', swapAcceptOffer);
        const hasErrors = Object.values(error).some((error) => error !== '');
        if (hasErrors) {
            setIsPending(false);  // Reset loading state if there are errors
            return;
        }
    
        const data = { message: swapAcceptOffer, swapId: offer._id, bidPartner: userData._id, senderType:"bidPartner", senderId:userData._id, receiverId:offer.user._id, seenStatus: false };
    
        try {
            const response = await axios.post("https://www.swaptime.co/swaptime_server/bid/addBid", data, {
                headers: { 'Content-Type': 'application/json' },
            });
    
            //console.log("Response is: ", response);
            if (response.status === 201) {
                setSwapAcceptOffer("");  // Clear input
                setSuccessMessage(true);
                setTimeout(() => {
                    setSuccessMessage(false);
                }, 3000);
                renderView("bid_page")
            } else {
                setServerError(response.data.error || 'An error occurred.');
                setTimeout(() => {
                    setServerError(null);
                }, 3000);
            }
        } catch (e) {
            setServerError(e.response ? e.response.data.message : e.message);
            setTimeout(() => {
                setServerError(null);
            }, 3000);
        } finally {
            setIsPending(false);  // Reset loading state in all cases
        }
    };
    
    return(
        <>
            {isPending &&  <div className='h-100 text-xxl mt-10' ><center><b className="fs-4">Sending Offer</b></center><img src={loader1} alt='loader' /></div>}
            {   
                !isPending &&
                <div>
                    <form className='me-3 ms-3' onSubmit={handleSubmit}>
                        {successMessage && <div className='alert alert-success'> Offer Sent Successfully</div>}
                        {serverError && <div className='alert alert-danger'>{serverError}</div>}
                        <div className='mt-2 mb-4'>
                            <p className='text-primary text-2xl font-bold text-justify'>{question? "Ask Question" :"Swap Accept Offer"}</p>
                        </div>
                        <div className='row'>
                            <div className='col-md-12 mb-3'>
                            <div className='float-start font-bold'>
                                {question? "What is your Question" :"What is your Offer?"}<i className='text-danger'>*</i>
                            </div><br/>
                            <textarea 
                                name="swapAcceptOffer" 
                                type='text'
                                className="rounded-lg px-6 py-3 w-full" 
                                value={swapAcceptOffer} 
                                onChange={(e)=>{
                                    setSwapAcceptOffer(e.target.value)
                                    validateField('swapAcceptOffer', swapAcceptOffer)
                                }} 
                                onBlur={() => validateField('swapAcceptOffer', swapAcceptOffer)}
                                id="swapAcceptOffer"
                            ></textarea>
                            {error.swapAcceptOffer && <p style={{float:"left"}} className="text-danger mt-1 mb-3 ml-2">{error.swapAcceptOffer}</p>}
                            </div>
                            <div className='d-grid d-block mb-2 mt-1'>
                                <center><button type="submit" className='btn btn-primary btn-md w-100'>{question? "Ask" :"Send Offer"}</button></center>
                            </div>
                        </div>
                    </form>
                </div>
            }
        </>
    )
}

export default AcceptOfferForm