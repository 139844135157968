import React, { useState } from "react";
import useCheckInternet from "../../generalComponents/checkOnline";
import axios from 'axios';
import { Modal } from 'react-bootstrap'; // Assuming you're using Bootstrap for modal

const ChatTextBox = ({userData, bid, handleUpdateResult, renderView}) => {
    const [successMessage, setSuccessMessage] = useState(null);
    const [serverError, setServerError] = useState(null);
    const [error, setError] = useState({});
    const [swapAcceptOffer, setSwapAcceptOffer] = useState("");
    const [isPending, setIsPending] = useState(false);
    const [isTextDisabled, setIsTextDisabled] = useState(false);
    const [showModal, setShowModal] = useState(false); // State to handle modal visibility

    const validateField = (field, value) => {
        let error = '';
        if (value !== undefined) {
            if (field === 'swapAcceptOffer') {
                if (value.trim() === '') {
                    error = 'Field is required';
                }
            }
        }
        setError((prevErrors) => ({ ...prevErrors, [field]: error }));
    };

    const isOnline = useCheckInternet();
    const handleSubmit = async (e) => {
        e.preventDefault(); 
        setIsPending(true);  
        setIsTextDisabled(true);

        if (!isOnline) {
            setServerError('You are offline. Please check your internet connection and try again.');
            setIsPending(false);
            setIsTextDisabled(false);
            return;
        }

        if (!swapAcceptOffer) {
            setServerError('Field is required');
            setIsPending(false);
            setIsTextDisabled(false);
            setTimeout(() => setServerError(null), 3000);
            return;
        }

        validateField('swapAcceptOffer', swapAcceptOffer);
        if (error.swapAcceptOffer) {
            setIsPending(false);
            setIsTextDisabled(false);
            setServerError(error.swapAcceptOffer);
            setTimeout(() => setServerError(null), 3000);
            return;
        }

        let senderType = '';
        let bidPartner = '';
        let receiverId = '';
        
        if (userData._id === bid.swapId.user._id) {
            senderType = "user";
            bidPartner = bid.bidPartner._id;
            receiverId = bid.bidPartner._id;
        } else {
            senderType = "bidPartner";
            bidPartner = userData._id;
            receiverId = bid.swapId.user._id;
        }
        const data = {
            message: swapAcceptOffer,
            swapId: bid.swapId._id,
            bidPartner: bidPartner,
            senderType: senderType,
            senderId: userData._id,
            receiverId: receiverId,
            seenStatus: false
        };

        try {
            const response = await axios.post("https://www.swaptime.co/swaptime_server/bid/addBid", data, {
                headers: { 'Content-Type': 'application/json' },
            });
            setSwapAcceptOffer("");
            if (response.status === 201) {
                setSuccessMessage(true);
                handleUpdateResult(response.data.bid, 'add');
                setTimeout(() => setSuccessMessage(false), 3000);
            } else {
                setServerError(response.data.error || 'An error occurred.');
                setTimeout(() => setServerError(null), 3000);
            }
        } catch (e) {
            setServerError(e.response ? e.response.data.message : e.message);
            setTimeout(() => setServerError(null), 3000);
        } finally {
            setIsPending(false);
        }
        setIsTextDisabled(false);
    };

    const handleFinalAccept = () => {
        setShowModal(true); // Open the modal when "Accept and Close Offer" is clicked
    };

    const handleModalClose = () => {
        setShowModal(false); // Close the modal
    };

    const handleDecline = async () => {
        const data = { swapId: bid.swapId._id, bidPartner: bid.bidPartner._id };
        const confirmed = prompt("Do you really want to Decline and close this chat? Type 'yes' to confirm.");
        if (confirmed && confirmed.toLowerCase() === 'yes') {
            try {
                const response = await axios.post("https://www.swaptime.co/swaptime_server/bid/deleteBid", data, {
                    headers: { 'Content-Type': 'application/json' },
                });
                if (response.status === 200) {
                    handleUpdateResult(bid._id, 'remove');
                    alert(`Bid offer from ${bid.bidPartner.name} has been Declined and Closed`);
                    renderView("bid_page");
                } else {
                    alert(`Error Closing bid offer: ${error.message}`);
                }
            } catch (error) {
                alert('An error occurred while trying to delete the bid offer.');
            }
        }
    };

    return (
        <>
            <div>
                <form className='me-3 ms-3' onSubmit={handleSubmit}>
                    {isPending && <div className='alert alert-warning'> Sending...</div>}
                    {serverError && <div className='alert alert-danger'>{serverError}</div>}
                    <div className='row'>
                        <div className='col-md-12 mb-1'>
                            <textarea 
                                name="swapAcceptOffer" 
                                type='text'
                                className="rounded-lg px-6 py-3 w-full"
                                value={swapAcceptOffer} 
                                onChange={(e) => setSwapAcceptOffer(e.target.value)} 
                                onBlur={() => validateField('swapAcceptOffer', swapAcceptOffer)}
                                id="swapAcceptOffer"
                            ></textarea>
                        </div>
                        <div className='d-grid d-block mb-2'>
                            <center><button type="submit" className={`btn btn-primary btn-md w-100 ${isTextDisabled ? 'disabled' : ''}`}>Send Message</button></center>
                        </div>
                        {
                            bid.swapId.user._id !== bid.swapId.partner._id && bid.swapId.partner._id === userData._id && (
                                <div className='d-grid d-block mb-2'>
                                    <center><button type="button" onClick={()=>renderView("set_milestone",bid)} className={`btn btn-success btn-md w-100 ${isTextDisabled ? 'disabled' : ''}`}>Set Up Project Milestones</button></center>
                                </div>
                            )
                        }
                        {bid.swapId.user._id === userData._id && (
                            <div>
                                <div className='d-grid d-block mb-2'>
                                    <center><button type="button" onClick={()=>renderView("set_milestone",bid)} className={`btn btn-success btn-md w-100 ${isTextDisabled ? 'disabled' : ''}`}>Accept and Start Project</button></center>
                                </div>
                                <div className='d-grid d-block mb-2'>
                                    <center><button type="button" onClick={handleDecline} className={`btn btn-danger btn-md w-100 ${isTextDisabled ? 'disabled' : ''}`}>Decline and Close Offer</button></center>
                                </div>
                            </div>
                        )}
                    </div>
                </form>
            </div>

            
            

            {/* Modal Implementation */}
            <Modal show={showModal} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Accept and Close Offer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to accept and close this offer?</p>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={handleModalClose}>Close</button>
                    <button className="btn btn-success" onClick={() => { 
                        handleModalClose(); 
                        handleFinalAccept(); 
                    }}>Accept</button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ChatTextBox;
