import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import loader1 from '../../assets/images/03-42-11-849_512.gif'
import axios from 'axios';
import defaultImage from '../../assets/images/userImage/defaultUserImage.png'

const CreateSwapForm = ({userData, onProfileUpdate, screenWidth}) => {
    const [swapName, setSwapName] = useState("")
    const [swapDesc, setSwapDesc] = useState("")
    const [swapType, setSwapType] = useState("")
    const [swapSkill, setSwapSkill] = useState("")
    const [swapNeedSkill, setSwapNeedSkill] = useState("")
    const [swapOfferCopy, setSwapOfferCopy] = useState("")
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [error,setError] = useState({})
    const [serverError,setServerError] = useState(null)
    const [isPending, setIsPending] = useState(false)
    const [successMessage, setSuccessMessage] = useState(false)

    // Get today's date and set it as the minimum date
    const today = new Date().toISOString().split('T')[0];

    const handleSubmit = async (e) =>{
        e.preventDefault()
        setIsPending(true)
        validateField('swapName', swapName)
        validateField('swapDesc', swapDesc)
        validateField('swapType', swapType)
        validateField('Need', swapSkill)
        validateField('swapNeedSkill', swapNeedSkill)
        validateField('swapOfferCopy', swapOfferCopy)
        validateField('startDate', startDate)
        validateField('endDate', endDate)

        const hasErrors = Object.values(error).some((error)=>error !== '')
        if(hasErrors){
            return;
        }

        const data = {swapName:swapName, swapDesc:swapDesc, swapType:swapType, swapSkill: swapSkill, swapNeedSkill: swapNeedSkill, swapProposal:swapOfferCopy, startDate:startDate, endDate:endDate, userId:userData._id};
            try{
                const response = await axios.post("https://www.swaptime.co/swaptime_server/swap/addUserSwapOffer", JSON.stringify(data), {headers: {'Content-Type': 'application/json'}})
                //alert(JSON.stringify(response))
                if(response.status === 201){
                    setSwapName("")
                    setSwapDesc("")
                    setSwapType("")
                    setSwapSkill("")
                    setSwapNeedSkill("")
                    setSwapOfferCopy("")
                    setStartDate("")
                    setEndDate("")
                    setSuccessMessage(true)
                    setIsPending(false)
                }else{
                    setIsPending(false)
                    setServerError(response.data.message)
                    setTimeout(() => {
                        setServerError(null)
                    }, 3000);
                }
            }catch(e){
                setServerError(e.message)
                setIsPending(false)
                setTimeout(() => {
                    setServerError(null)
                }, 3000);
            }

        setIsPending(false)
    }

    const validateField = (field, value) => {
        let error = '';
        if(value !== undefined){
            if (field === 'swapName') {
                if (value.trim() === '') {
                    error = 'Your Project Name is required';
                }
            }if (field === 'swapDesc') {
                if (value.trim() === '') {
                    error = 'Your Project Description is required';
                }
            }if (field === 'swapType') {
                if (value.trim() === '') {
                    error = 'Your Project Type is required';
                }
            }else if (field === 'swapSkill') {
                if (value.trim() === '') {
                    error = 'Please choose the skill you want to offer';
                }
            }else if (field === 'swapNeedSkill') {
                if (value.trim() === '') {
                    error = 'Please enter the skill you need to complete this project';
                }
            }else if (field === 'swapOfferCopy') {
                if (value.trim() === '') {
                    error = 'Swap Offer Proposal is Required';
                }
            }/*else if (field === 'startDate') {;
                if (value.trim() === '') {
                    error = 'Start date is Required';
                }
            }*/else if (field === 'endDate') {
                if (value.trim() === '') {
                    error = 'End date is Required';
                }
            } 
        }
        setError((prevErrors) => ({ ...prevErrors, [field]: error }));
    };
    return(
        <>
            {isPending &&  <div className='h-100 text-xxl mt-10' ><img src={loader1} alt='loader' /></div>}
            {successMessage && <div className='alert alert-success'> Swap Proposal Created Successfully</div>}
            {   
                !isPending && !successMessage &&
                <form className='me-3 ms-3' onSubmit={handleSubmit}>
                    {serverError && <div className='alert alert-danger'>{serverError}</div>}
                    <div className='mt-2 mb-4'>
                        <p className='text-primary text-4xl font-bold text-justify'>Create Swap Proposal</p>
                    </div>
                    <div className='row'>
                        <div className='col-md-12 mb-3'>
                            <div className='float-start font-bold'>
                                Project Name<i className='text-danger'>*</i>
                            </div><br/>
                            <input 
                                name="swapName" 
                                type='text'
                                className="rounded-lg px-6 py-3 w-full" 
                                value={swapName} 
                                onChange={(e)=>{setSwapName(e.target.value)}} 
                                onBlur={() => validateField('swapName', swapName)}
                                id="swapName"
                            />
                            {error.swapName && <p style={{float:"left"}} className="text-danger mt-1 mb-3 ml-2">{error.swapName}</p>}
                        </div>
                        <div className='col-md-12 mb-2'>
                            <div className='float-start font-bold'>
                                Explain what you need to bring your project to life<i className='text-danger'>*</i>
                            </div><br/>
                            
                            <textarea 
                                className='rounded-lg px-6 py-3 w-full' 
                                value={swapDesc} 
                                onChange={(e)=>{setSwapDesc(e.target.value)}}
                                onBlur={() => validateField('swapDesc', swapDesc)}
                            ></textarea>
                            {error.swapDesc && <p style={{float:"left"}} className="text-danger mt-1 mb-3 ml-2">{error.swapDesc}</p>}
                        </div>
                        <div className='col-md-12 mb-3'>
                            <div className='float-start font-bold'>
                                What skill do you need for this request?<i className='text-danger'>*</i>
                            </div><br/>
                            <input 
                                name="swapNeedSkill" 
                                type='text'
                                className="rounded-lg px-6 py-3 w-full" 
                                value={swapNeedSkill} 
                                onChange={(e)=>{setSwapNeedSkill(e.target.value)}} 
                                onBlur={() => validateField('swapNeedSkill', swapNeedSkill)}
                                id="swapNeedSkill"
                            />
                            {error.swapNeedSkill && <p style={{float:"left"}} className="text-danger mt-1 mb-3 ml-2">{error.swapNeedSkill}</p>}
                        </div><div className='col-md-12 mb-3'>
                            <div className='float-start font-bold'>
                                What type of offer are you making for this project?<i className='text-danger'>*</i>
                            </div><br/>
                            <select 
                                name="swapType" 
                                className="rounded-lg px-6 py-3 w-full" 
                                value={swapType} 
                                onChange={(e)=>{setSwapType(e.target.value)}} 
                                onBlur={() => validateField('swapType', swapType)}
                                id="swapType"
                            >
                                <option disabled>Select the offer type</option>
                                <option value="skill">Skill</option>
                                <option value="service">Service</option>
                                <option value="product">Product</option>
                            </select>
                            {error.swapType && <p style={{float:"left"}} className="text-danger mt-1 mb-3 ml-2">{error.swapType}</p>}
                        </div>
                        <div className='col-md-12 mb-3'>
                            <div className='float-start font-bold'>
                                What would you like to offer for this swap request?<i className='text-danger'>*</i>
                            </div><br/>
                            <input
                                type='text'
                                name="swapSkill" 
                                className="rounded-lg px-6 py-3 w-full" 
                                value={swapSkill} 
                                onChange={(e)=>{setSwapSkill(e.target.value)}} 
                                onBlur={() => validateField('swapSkill', swapSkill)}
                                id="swapSkill"
                            />
                                {/*<option >Select the Skill for this Swap offer</option>
                                {
                                   
                                    userData.skills_possessed.map((skill, index)=>(
                                        <option key={index} value={skill._id}>{skill.name}</option>
                                    ))
                                }
                            </select>*/}
                            {error.swapSkill && <p style={{float:"left"}} className="text-danger mt-1 mb-3 ml-2">{error.swapSkill}</p>}
                        </div>
                        <div className='col-md-12 mb-2'>
                            <div className='float-start font-bold'>
                                Write your Swap Offer<i className='text-danger'>*</i>
                            </div><br/>
                            
                            <textarea 
                                type='text'
                                className='rounded-lg px-6 py-3 w-full' 
                                value={swapOfferCopy} 
                                onChange={(e)=>{setSwapOfferCopy(e.target.value)}}
                                onBlur={() => validateField('swapOfferCopy', swapOfferCopy)}
                            ></textarea>
                            {error.swapOfferCopy && <p style={{float:"left"}} className="text-danger mt-1 mb-3 ml-2">{error.swapOfferCopy}</p>}
                        </div>
                        <div className='col-md-12 mb-2'>
                            <div className='float-start font-bold'>
                            I need this by<i className='text-danger'>*</i>
                            </div><br/>
                            
                            <input
                                name='endDate'
                                className='rounded-lg px-6 py-3 w-full' 
                                value={endDate} 
                                type='date'
                                min={today}
                                onChange={(e)=>{setEndDate(e.target.value)}}
                                onBlur={() => validateField('endDate', endDate)}
                            />
                            {error.endDate && <p style={{float:"left"}} className="text-danger mt-1 mb-3 ml-2">{error.endDate}</p>}
                        </div>
                        <div className='col-md-6 mb-0'>
                            <div className='float-start font-bold'>
                                {/*Start Date*/}
                            </div><br/>
                            <input
                                name='startDate'
                                className='rounded-lg px-6 py-3 w-full'
                                type='hidden' 
                                min={today}
                                value={startDate} 
                                onChange={(e)=>{setStartDate(e.target.value)}}
                                onBlur={() => validateField('startDate', startDate)}
                            />
                            {/*error.startDate && <p style={{float:"left"}} className="text-danger mt-1 mb-3 ml-2">{error.startDate}</p>*/}
                        </div>

                        <div className='d-grid d-block mb-2 mt-3'>
                            <button type="submit" className='btn btn-primary btn-lg w-100'>Create Swap</button>
                        </div>
                    </div>
                </form>
            }
        </>
    )
}

export default CreateSwapForm;