import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import useFetch from '../../custom_hooks/useFetch';
import loader1 from '../../../assets/images/03-42-11-849_512.gif'

const MilestoneLogic = ({ userData, bid, renderView = null, type = 'user' }) => {
    const [milestones, setMilestones] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [deliverableModal, setDeliverableModal] = useState(false);
    const [selectedMilestone, setSelectedMilestone] = useState(null);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [milestoneStatus, setMilestoneStatus] = useState('');
    const [dueDate, setDueDate] = useState('');
    const [deliverables, setDeliverables] = useState([]);
    const [serverError, setServerError] = useState(null);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [infoMessage, setInfoMessage] = useState(null);
    const [swap, setSwap] = useState(bid.swapId);
    const [allEditable, setAllEditable] = useState(true)

    useEffect(() => {
        if(type === 'user' && userData._id !== swap.user._id){
            setAllEditable(false)
        }else if(type === 'bidPartner' && userData._id === swap.user._id){
            setAllEditable(false)
        }else{
            setAllEditable(true)
        }
    },[type])

    console.log(swap)
    const {result, isResultPending, resultError} = useFetch(`https://www.swaptime.co/swaptime_server/milestone/getMilestone/${swap._id}/${type}`)

    // Fetch milestones on load
    useEffect(() => {
        if(!isResultPending && result){
            
            console.log(result)
            if(result.milestones){
                setMilestones(result.milestones)
            }
        }
    }, [result, isResultPending]);

    // Add a new deliverable
    const handleAddDeliverable = () => {
        setDeliverables([...deliverables, '']);
    };
    //console.log("The milestone: ", milestones)

    const handleDeliverableChange = (index, value) => {
        const newDeliverables = [...deliverables];
        newDeliverables[index] = value;
        setDeliverables(newDeliverables);
    };

    const handleRemoveDeliverable = (index) => {
        const newDeliverables = deliverables.filter((_, i) => i !== index);
        setDeliverables(newDeliverables);
    };

    // Save Milestone (Add or Edit)
    const handleSaveMilestone = async () => {
        setButtonDisabled(true);
        let milestoneOwnerType = userData._id === swap.user._id ? 'user' : 'bidPartner';
        console.log("The bid in SetMilestone: ", bid)
        const newMilestone = {
            title,
            desc: description,
            swapId: swap._id,
            milestoneOwnerType,
            deliverables,
            status:milestoneStatus,
            dueDate,
            bidPartnerId:bid.bidPartner._id,
        };
        /*console.log("New milestone: ", newMilestone)
        console.log("Del length: ", deliverables.length)
        console.log("Value 1: ", deliverables[0])
        if(deliverables.length === 0 && !deliverables[0]){
            setDeliverables(null)
        }*/

        try {
            const response = isEditing
                ? await axios.put(`https://www.swaptime.co/swaptime_server/milestone/updateMilestone/${selectedMilestone._id}`, newMilestone, {
                      headers: { 'Content-Type': 'application/json' },
                  })
                : await axios.post('https://www.swaptime.co/swaptime_server/milestone/addMilestone', newMilestone, {
                      headers: { 'Content-Type': 'application/json' },
                  });

            if (response.status === 201 || response.status === 200) {
                if (isEditing) {
                    // Update milestone in state
                    setMilestones(milestones.map(m => (m._id === selectedMilestone._id ? response.data.milestone : m)));
                    //setDeliverables(milestones.deliverables.map(d => (selectedMilestone.deliverables.includes(d._id) ? response.data.milestone.deliverables : d)));
                } else {
                    setMilestones([...milestones, response.data.milestone]);
                    //setDeliverables([...deliverables,response.data.milestone.deliverables])
                }
                setShowModal(false);
                setEditModal(false);
                setTitle('');
                setDescription('');
                setDueDate('');
                setDeliverables(['']);
            } else {
                setServerError(response.data.error || 'An error occurred.');
                setTimeout(() => setServerError(null), 3000);
            }
        } catch (e) {
            setServerError(e.response ? e.response.data.message : e.message);
            setTimeout(() => setServerError(null), 3000);
        }
        setButtonDisabled(false);
    };

    // Delete Milestone
    const handleDeleteMilestone = async (milestone) => {
        setServerError("Deleting...");
        try {
            const response = await axios.post('https://www.swaptime.co/swaptime_server/milestone/deleteMilestone', JSON.stringify({milestoneId: milestone._id}) ,{
                headers: {
                  'Content-Type': 'application/json',
                },
              });
            if (response.status === 200) {
                setServerError(null)
                setInfoMessage("Milestone Deleted")
                setDeliverables(deliverables.filter(d => !milestone.deliverables.includes(d._id)));
                setMilestones(milestones.filter(m => m._id !== milestone._id));
                setTimeout(() => {
                    setInfoMessage(null)
                }, 3000);
            }
        } catch (error) {
            console.error('Error deleting milestone:', error);
            setServerError(error.message)
            setTimeout(() => {
                setServerError(null)
            }, 3000);
        }
    };

    // Delete Milestone Deliverable
const handleDeleteDeliverable = async (deliverable) => {
    setServerError("Deleting...");
    try {
        // Send request to delete the deliverable
        const response = await axios.post(
            'https://www.swaptime.co/swaptime_server/milestone/deleteDeliverable',
            JSON.stringify({ delId: deliverable._id }),
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );

        if (response.status === 200) {
            // Successfully deleted deliverable
            setServerError(null);
            setInfoMessage("Deliverable Deleted");
            
            // Remove the deleted deliverable from the local state
            setDeliverables(deliverables.filter(d => d._id !== deliverable._id));
            
            // Clear info message after 3 seconds
            setTimeout(() => {
                setInfoMessage(null);
            }, 3000);
        }
    } catch (error) {
        console.error('Error deleting Deliverable:', error);
        setServerError(error.message);
        
        // Clear server error after 3 seconds
        setTimeout(() => {
            setServerError(null);
        }, 3000);
    }
};


    // Open edit modal and populate fields
    const handleEditMilestone = (milestone) => {
        setSelectedMilestone(milestone);
        setTitle(milestone.title);
        setDescription(milestone.desc);
        setDueDate(milestone.dueDate);
        setDeliverables(milestone.deliverables || ['']);
        setIsEditing(true);
        setEditModal(true);
    };

    // View Deliverables
    const handleViewDeliverables = (milestone) => {
        console.log("The deliverables: ", )
        setSelectedMilestone(milestone);
        setDeliverables(milestone.deliverables || ['']);
        setDeliverableModal(true);
        
        console.log("The deliverables: ", deliverables)
        console.log("The milestone: ", milestone)
    };
    
    function humanDate(isoDate) {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const date = new Date(isoDate);
        return date.toLocaleDateString(undefined, options);
    }

    function convertToHTMLDate(dateString) {
        const date = new Date(dateString); // Convert the string into a Date object
    
        // Extract the year, month, and day
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-indexed, so we add 1
        const day = String(date.getUTCDate()).padStart(2, '0'); // Pad day with leading zero if needed
    
        // Return the date in the format "YYYY-MM-DD"
        return `${year}-${month}-${day}`;
    }

    const checkStatus = (status) =>{
        switch (status) {
            case "completed":
                return <div className='badge badge-primary'>Completed</div>
            break;
            case "in_progress":
                return <div className='badge badge-warning'>In Progress</div>
            break;
            case "declined":
                return <div className='badge badge-danger'>Declined</div>
            break;
            default:
                return <div className='badge badge-dark'>Not Started</div>
            break;
        }
    }

    return (
        <div className="container">
            {allEditable &&<Button onClick={() => setShowModal(true)} className="mb-3 mt-1">
                Add Milestone
            </Button>}
            {serverError && <div className="alert alert-danger py-2">{serverError}</div>}
            {infoMessage && <div className="alert alert-info py-2">{infoMessage}</div>}
            <table className="table table-responsive table-striped table-hovered" style={{overflow:"auto",maxHeight:"500px;"}}>
                <thead>
                    <tr>
                        <th>Title</th>
                        <th>Description</th>
                        <th>Status</th>
                        <th>Due Date</th>
                        <th></th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {isResultPending?(
                        <center className='fs-3 mt-3 row'><div className='h-25 mt-2' >Loading Milestones...<br/><img src={loader1} alt='loader' /></div></center>
                    ):milestones && milestones.length>0?(
                        milestones.map((milestone, index) => (
                        <tr key={index}>
                            <td>{milestone.title}</td>
                            <td>{milestone.desc}</td>
                            <td>{checkStatus(milestone.status)}</td>
                            <td>{humanDate(milestone.dueDate)}</td>
                            <td><Button className= "btn btn-primary w-100" onClick={() => handleViewDeliverables(milestone)}>Deliverables</Button></td>
                            <td>{allEditable &&<Button className={`btn btn-warning w-100 ${allEditable?"":"disabled"}`} onClick={() => handleEditMilestone(milestone)}>Edit</Button>}</td>
                            <td>{allEditable &&<Button className={`btn btn-danger w-100 ${allEditable?"":"disabled"}`} onClick={() => handleDeleteMilestone(milestone)}>Delete</Button>}</td>
                        </tr>
                    ))):(
                        <center className='fs-5 mt-3 row'>No milestones created yet!<br></br>Create your milestones by clicking on Add Milestone</center>
                    )}
                </tbody>
            </table>

            {/* Modal for adding/editing a milestone */}
            <Modal show={showModal || editModal} onHide={() => { setShowModal(false); setEditModal(false); setIsEditing(false); }}>
                <Modal.Header closeButton>
                    <Modal.Title>{isEditing ? 'Edit Milestone' : 'Add New Milestone'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {serverError && <div className="alert alert-danger py-2">{serverError}</div>}
                    <form>
                        <div className="mb-3">
                            <label htmlFor="title" className="form-label">Title</label>
                            <input type="text" className="form-control" id="title" value={title} onChange={(e) => setTitle(e.target.value)} disabled={buttonDisabled} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="description" className="form-label">Description</label>
                            <textarea className="form-control" id="description" value={description} onChange={(e) => setDescription(e.target.value)} disabled={buttonDisabled}></textarea>
                        </div>
                        {editModal ? (
                            <div className="mb-3">
                                <label htmlFor="description" className="form-label">Milestone Status</label>
                                <select
                                    className='form-control'
                                    value={milestoneStatus}
                                    onChange={(e) => setMilestoneStatus(e.target.value)} 
                                    disabled={buttonDisabled}
                                >
                                    <option value="not_started">Not Started</option>
                                    <option value="in_progress">In Progress</option>
                                    <option value="completed">Completed</option>
                                    <option value="declined">Declined</option>
                                </select>
                            </div>
                        ):(
                            <span></span>
                        )}
                        <div className="mb-3">
                            <label htmlFor="dueDate" className="form-label">Due Date</label>
                            <input type="date" className="form-control" id="dueDate" value={convertToHTMLDate(dueDate)} onChange={(e) => setDueDate(e.target.value)} disabled={buttonDisabled} />
                        </div>
                        <div className="mb-3">
                            <label>Deliverables</label>
                            {deliverables && deliverables.length>0 && deliverables.map((deliverable, index) => (
                                <div key={index} className="input-group mb-2">
                                    <input type="text" className="form-control" placeholder={`Deliverable #${index + 1}`} value={deliverable.title} onChange={(e) => handleDeliverableChange(index, e.target.value)} disabled={buttonDisabled} />
                                    <Button type="button" className="btn btn-danger" onClick={() => handleRemoveDeliverable(index)} disabled={buttonDisabled}>Remove</Button>
                                </div>
                            ))}
                            {allEditable && <Button variant="secondary" onClick={handleAddDeliverable} disabled={buttonDisabled}>Add Deliverable</Button>}
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { setShowModal(false); setEditModal(false); setIsEditing(false);}}>Close</Button>
                    {allEditable && <Button variant="primary" onClick={handleSaveMilestone} disabled={buttonDisabled}>{buttonDisabled ? 'Saving...' : isEditing ? 'Save Changes' : 'Save Milestone'}</Button>}
                </Modal.Footer>
            </Modal>

            {/* Modal for viewing deliverables */}
            <Modal show={deliverableModal} onHide={() => setDeliverableModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Deliverables for {selectedMilestone ? selectedMilestone.title  : " "}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {serverError && <div className="alert alert-danger py-2">{serverError}</div>}
                    {infoMessage && <div className="alert alert-info py-2">{infoMessage}</div>}
                    <table className="table table-responsive table-striped table-hovered">
                        <thead>
                            <tr>
                                <th>Title</th>
                                <th>Status</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {isResultPending?(
                                <center className='fs-3 mt-3 row'><div className='h-25 mt-2' >Loading Deliverables...<br/><img src={loader1} alt='loader' /></div></center>
                            ):deliverables && deliverables.length>0?(
                                deliverables.map((deliverable, index) => (
                                <tr key={index}>
                                    <td>{deliverable.title}</td>
                                    <td>{
                                            deliverable.submittedStatus === false?(
                                                <span className='badge badge-warning'>Pending</span>
                                            ):(
                                                <span className='badge badge-success'>Submitted</span>
                                            )
                                        }
                                    </td>
                                    <td>
                                        <form id="fileUploadForm">
                                            <input 
                                                type="file" 
                                                className="hidden" 
                                                placeholder="Upload File"
                                                name="delFile"
                                                accept='image/*'
                                                id="imageUploadInput"
                                            />
                                            {/*<button type="submit" className='btn btn-primary' id="uploadDeliverableBtn">Upload</button>*/}
                                        </form>
                                        {allEditable &&  <Button className="btn btn-primary w-100" onClick={() => alert("File Upload Function Under Construction...")}>Upload</Button>}
                                    </td>
                                    <td>{allEditable && <Button className="btn btn-danger w-100" onClick={() => handleDeleteDeliverable(deliverable)}>Delete</Button>}</td>
                                </tr>
                            
                            ))):(
                                <center className='fs-5 mt-3 row'>No Deliverables created yet!<br></br>Add your deliverables by clicking on Editing the milestone</center>
                            )}
                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setDeliverableModal(false)}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default MilestoneLogic;
