import React, { useState, useEffect } from 'react';
import axios from 'axios';
import loader1 from '../../assets/images/03-42-11-849_512.gif';
import defaultImage from '../../assets/images/userImage/defaultUserImage.png';
import useFetch from '../custom_hooks/useFetch';

const EditSwapForm = ({ userData, screenWidth, swapId, renderView = null }) => {
    const [swapName, setSwapName] = useState("Loading...");
    const [swapDesc, setSwapDesc] = useState("Loading...");
    const [swapType, setSwapType] = useState("Loading...");
    const [swapSkill, setSwapSkill] = useState("Loading...");
    const [swapNeedSkill, setSwapNeedSkill] = useState("Loading...");
    const [swapOfferCopy, setSwapOfferCopy] = useState("Loading...");
    const [startDate, setStartDate] = useState("Loading...");
    const [endDate, setEndDate] = useState("Loading...");
    const [error, setError] = useState({});
    const [serverError, setServerError] = useState(null);
    const [isPending, setIsPending] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false)

    const { result, isResultPending, resultError } = useFetch(`https://www.swaptime.co/swaptime_server/swap/getSwapOffer/${swapId}`);

    // Use effect to set swap data once the result is fetched
    useEffect(() => {
        if (!isResultPending) {
            setSwapName(result.swapOffer.swapName || "");
            setSwapDesc(result.swapOffer.swapDesc || "");
            setSwapType(result.swapOffer.swapType || "");
            setSwapSkill(result.swapOffer.swapSkill.name || "");
            setSwapNeedSkill(result.swapOffer.swapNeedSkill.name || "");
            setSwapOfferCopy(result.swapOffer.swapProposal || "");
            setStartDate(result.swapOffer.startDate || "");
            setEndDate(result.swapOffer.endDate || "");
            setIsLoaded(true)
        }
    }, [result]);

    const today = new Date().toISOString().split('T')[0]; // Get today's date

    function convertToHTMLDate(dateString) {
        const date = new Date(dateString); // Convert the string into a Date object
    
        // Extract the year, month, and day
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-indexed, so we add 1
        const day = String(date.getUTCDate()).padStart(2, '0'); // Pad day with leading zero if needed
    
        // Return the date in the format "YYYY-MM-DD"
        return `${year}-${month}-${day}`;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsPending(true);

        // Validate all fields
        const fieldsToValidate = { swapName, swapDesc, swapType, swapSkill, swapNeedSkill, swapOfferCopy, endDate };
        Object.keys(fieldsToValidate).forEach(field => validateField(field, fieldsToValidate[field]));

        // Check for any errors
        const hasErrors = Object.values(error).some(err => err !== '');
        if (hasErrors) {
            setIsPending(false);
            return;
        }

        const data = {
            swapId,
            swapName,
            swapDesc,
            swapType,
            swapSkill,
            swapNeedSkill,
            swapProposal: swapOfferCopy,
            endDate,
        };

        try {
            const response = await axios.post(
                "https://www.swaptime.co/swaptime_server/swap/updateUserSwapOffer",
                JSON.stringify(data),
                { headers: { 'Content-Type': 'application/json' } }
            );

            if (response.status === 201) {
                // Clear form
                setSuccessMessage(true);

                // Hide success message after 3 seconds
                setTimeout(() => setSuccessMessage(false), 3000);
            } else {
                setServerError(response.data.message);
                setTimeout(() => setServerError(null), 3000);
            }
        } catch (e) {
            setServerError(e.message);
            setTimeout(() => setServerError(null), 3000);
        } finally {
            setIsPending(false);
        }
    };

    const validateField = (field, value) => {
        let errorMessage = '';

        if (value.trim() === '') {
            switch (field) {
                case 'swapName':
                    errorMessage = 'Your Project Name is required';
                    break;
                case 'swapDesc':
                    errorMessage = 'Your Project Description is required';
                    break;
                case 'swapType':
                    errorMessage = 'Your Project Type is required';
                    break;
                case 'swapSkill':
                    errorMessage = 'Please choose the skill you want to offer';
                    break;
                case 'swapNeedSkill':
                    errorMessage = 'Please enter the skill you need to complete this project';
                    break;
                case 'swapOfferCopy':
                    errorMessage = 'Swap Offer Proposal is required';
                    break;
                case 'endDate':
                    errorMessage = 'End date is required';
                    break;
                default:
                    break;
            }
        }

        setError((prevErrors) => ({ ...prevErrors, [field]: errorMessage }));
    };

    const handleDelete = async () => {
        const confirmed = prompt("Do you really want to delete this Swap Request? Type 'yes' to confirm.");
    
        if (confirmed && confirmed.toLowerCase() === 'yes') {
          try {
            const response = await fetch(`https://www.swaptime.co/swaptime_server/swap/deleteSwapRequest/${swapId}`, {
              method: 'GET', // Use DELETE if your backend supports it
              headers: {
                'Content-Type': 'application/json',
              },
            });
            console.log(response);
            if (response.status === 200) {
                alert('Swap Deleted Successfully...');
                renderView("my_offers");
                
            } else {
                alert(`Error deleting swap offer: ${error.message}`);
            }
          } catch (error) {
            console.error('Error deleting swap offer:', error);
            alert('An error occurred while trying to delete the swap offer.');
          }
        } else {
          console.log('Delete action canceled.');
        }
      };

    return (
        <>
            {isPending && <div className='h-100 text-xxl mt-10'><center><b>Updating Swap...</b><br/><img src={loader1} alt='loader' /></center></div>}
            {successMessage && <div className='alert alert-success'>Swap Proposal Updated Successfully</div>}
            {!isResultPending && !isPending && !successMessage && (
                <form className='me-3 ms-3' onSubmit={handleSubmit}>
                    {serverError && <div className='alert alert-danger'>{serverError}</div>}
                    <div className='mt-2 mb-4'>
                        <p className='text-primary text-4xl font-bold text-justify'>Edit Swap Proposal</p>
                    </div>
                    <div className='row'>
                        {/* Swap Name */}
                        <div className='col-md-12 mb-3'>
                            <label className='float-start font-bold'>
                                Project Name <i className='text-danger'>*</i>
                            </label><br />
                            <input
                                name="swapName"
                                type='text'
                                className="rounded-lg px-6 py-3 w-full"
                                value={swapName}
                                onChange={(e) => setSwapName(e.target.value)}
                                onBlur={() => validateField('swapName', swapName)}
                                disabled={!isLoaded}
                            />
                            {error.swapName && <p className="text-danger mt-1 mb-3 ml-2">{error.swapName}</p>}
                        </div>

                        {/* Swap Description */}
                        <div className='col-md-12 mb-2'>
                            <label className='float-start font-bold'>
                                Explain what you need to bring your project to life <i className='text-danger'>*</i>
                            </label><br />
                            <textarea
                                className='rounded-lg px-6 py-3 w-full'
                                value={swapDesc}
                                onChange={(e) => setSwapDesc(e.target.value)}
                                onBlur={() => validateField('swapDesc', swapDesc)}
                                disabled={!isLoaded}
                            ></textarea>
                            {error.swapDesc && <p className="text-danger mt-1 mb-3 ml-2">{error.swapDesc}</p>}
                        </div>

                        {/* Swap Type */}
                        <div className='col-md-12 mb-3'>
                            <label className='float-start font-bold'>
                                What type of offer are you making for this project? <i className='text-danger'>*</i>
                            </label><br />
                            <select
                                name="swapType"
                                className="rounded-lg px-6 py-3 w-full"
                                value={swapType}
                                onChange={(e) => setSwapType(e.target.value)}
                                onBlur={() => validateField('swapType', swapType)}
                                disabled={!isLoaded}
                            >
                                <option disabled>Select the offer type</option>
                                <option value="skill">Skill</option>
                                <option value="service">Service</option>
                                <option value="product">Product</option>
                            </select>
                            {error.swapType && <p className="text-danger mt-1 mb-3 ml-2">{error.swapType}</p>}
                        </div>
                        <div className='col-md-12 mb-3'>
                            <div className='float-start font-bold'>
                                What would you like to offer for this swap request?<i className='text-danger'>*</i>
                            </div><br/>
                            <input
                                type='text'
                                name="swapSkill" 
                                className="rounded-lg px-6 py-3 w-full" 
                                value={swapSkill} 
                                onChange={(e)=>{setSwapSkill(e.target.value)}} 
                                onBlur={() => validateField('swapSkill', swapSkill)}
                                id="swapSkill"
                                disabled={!isLoaded}
                            />
                                {/*<option >Select the Skill for this Swap offer</option>
                                {
                                   
                                    userData.skills_possessed.map((skill, index)=>(
                                        <option key={index} value={skill._id}>{skill.name}</option>
                                    ))
                                }
                            </select>*/}
                            {error.swapSkill && <p style={{float:"left"}} className="text-danger mt-1 mb-3 ml-2">{error.swapSkill}</p>}
                        </div>
                        <div className='col-md-12 mb-2'>
                            <div className='float-start font-bold'>
                                Write your Swap Offer<i className='text-danger'>*</i>
                            </div><br/>
                            
                            <textarea 
                                className='rounded-lg px-6 py-3 w-full' 
                                value={swapOfferCopy} 
                                onChange={(e)=>{setSwapOfferCopy(e.target.value)}}
                                onBlur={() => validateField('swapOfferCopy', swapOfferCopy)}
                                disabled={!isLoaded}
                            ></textarea>
                            {error.swapOfferCopy && <p style={{float:"left"}} className="text-danger mt-1 mb-3 ml-2">{error.swapOfferCopy}</p>}
                        </div>
                        <div className='col-md-12 mb-2'>
                            <div className='float-start font-bold'>
                            I need this by<i className='text-danger'>*</i>
                            </div><br/>
                            
                            <input
                                name='endDate'
                                className='rounded-lg px-6 py-3 w-full' 
                                value={convertToHTMLDate(endDate)} 
                                type='date'
                                min={today}
                                onChange={(e)=>{setEndDate(e.target.value)}}
                                onBlur={() => validateField('endDate', endDate)}
                                disabled={!isLoaded}
                            />
                            {error.endDate && <p style={{float:"left"}} className="text-danger mt-1 mb-3 ml-2">{error.endDate}</p>}
                        </div>
                        <div className='col-md-6 mb-0'>
                            <div className='float-start font-bold'>
                                {/*Start Date*/}
                            </div><br/>
                            <input
                                name='startDate'
                                className='rounded-lg px-6 py-3 w-full'
                                type='hidden' 
                                min={today}
                                value={startDate} 
                                onChange={(e)=>{setStartDate(e.target.value)}}
                                onBlur={() => validateField('startDate', startDate)}
                                disabled={!isLoaded}
                            />
                            {/*error.startDate && <p style={{float:"left"}} className="text-danger mt-1 mb-3 ml-2">{error.startDate}</p>*/}
                        </div>

                        <div className='d-grid d-block mb-1 mt-3'>
                            <button type="submit" className='btn btn-primary btn-lg w-100'>Update Swap</button>
                        </div>
                        <div className='d-grid d-block mb-2 mt-1'>
                            <button onClick={handleDelete} className='btn btn-danger btn-md w-100'>Delete Swap</button>
                        </div>
                    </div>
                </form>
            )}
        </>
    );
};

export default EditSwapForm;
