import React, { useState, useEffect } from 'react';
import loader1 from '../../assets/images/03-42-11-849_512.gif'
import axios from 'axios';
import defaultImage from '../../assets/images/userImage/defaultUserImage.png'
import useFetch from '../custom_hooks/useFetch';

const OnboardForm = ({userData, onProfileUpdate, screenWidth}) => {
    let nameArray = userData.name.split(" ")
    const arrayToStringDisplay = (array) =>{
        if(Array.isArray(array) && array.length>0){
            return array.join(",")
        }else{
            return "";
        }
    }
    
    const ObjectNameToString = (data) => {
        if (!Array.isArray(data)) {
            return '';  // Return an empty string if data is not an array
          }
        
          return data.map(item => item.name).join(', ');
      } 
    
    const [fname, setFName] = useState(nameArray[0])
    const [lname, setLName] = useState(nameArray[1])
    const [username, setUsername] = useState(userData.username)
    const [isUsernameAvailable, setIsUsernameAvailable] = useState(null); // Availability state
    const [email, setEmail] = useState(userData.email)
    const [country, setCountry] = useState(userData.country.name)
    const [bio, setBio] = useState(userData.bio)
    const [proofOfWork, setProofOfWork] = useState(arrayToStringDisplay(userData.proof_of_work))
    const [urlErrors, setUrlErrors] = useState([]);
    const [isPending, setIsPending] = useState(false)
    const [error, setError] = useState({});
    const [serverError, setServerError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(false);
    const [progress, setProgress] = useState(0);
    const [profileImage, setProfileImage] = useState(userData.profileImage);
    const [profileImagePreview, setProfileImagePreview] = useState(defaultImage); 
    const [saveReminder, setSaveReminder] = useState(false)
    const maxCharacters = 350;  // Maximum character limit
    const [remainingChars, setRemainingChars] = useState(maxCharacters);
    const { result, isResultPending, resultError } = useFetch('https://www.swaptime.co/swaptime_server/country/getCountry');
    

    useEffect(() => {
        if(userData.profileImage !== null && userData.profileImage !== ''){
            setProfileImagePreview(userData.profileImage)
        }
    }, [profileImage,userData.profileImage]);

    const handleBioChange = (e) => {
        const input = e.target.value;

        // Only update bio if input length is within the limit
        if (input.length <= maxCharacters) {
            setBio(input);
            setRemainingChars(input.length);
        }
    };
    
    
    const validateField = (field, value) => {
        let error = '';
        if (value !== undefined) {
            if (field === 'fname') {
                if (value.trim() === '') {
                    error = 'First Name is required';
                }
            } else if (field === 'lname') {
                if (value.trim() === '') {
                    error = 'Last Name is required';
                }
            } else if (field === 'username') {
                if (value.trim() === '') {
                    error = 'Username is required';
                }
            } else if (field === 'email') {
                // Basic email validation (adjust as needed)
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (!emailRegex.test(value)) {
                    error = 'Invalid email address';
                }
            } else if (field === 'country') {
                if (value.trim() === '') {
                    error = 'Country is required';
                }
            } else if (field === 'bio') {
                if (value.trim() === '') {
                    error = 'Bio is required';
                }
            } else if (field === 'proofOfWork') {
                if (value.trim() === '') {
                    error = 'Proof of Work is required';
                } else {
                    // Split the value by commas and validate each URL
                    const urls = value.split(',').map(url => url.trim());
                    const invalidUrls = [];
    
                    // Validate each URL
                    urls.forEach(url => {
                        try {
                            const formattedUrl = validateAndFormatURL(url); // Reuse the URL formatting/validation function
                        } catch (_) {
                            invalidUrls.push(url); // Collect invalid URLs
                        }
                    });
    
                    // Set error message if there are any invalid URLs
                    if (invalidUrls.length > 0) {
                        error = `Invalid URLs: ${invalidUrls.join(', ')}`;
                    }
                }
            }
        }
        setError((prevErrors) => ({ ...prevErrors, [field]: error }));
    };
    
    /*useEffect(() => {
        if (userData) {
            let link = `userData.profileImage`
            setFName(userData.name.split(' ')[0]);
            setLName(userData.name.split(' ')[1]);
            setEmail(userData.email);
            setCountry(userData.country);
            setSkillsPossessed(userData.skills_possessed.join(', '));
            setSkillsRequired(userData.skills_required.join(', '));
            setProofOfWork(userData.proof_of_work.join(', '));
            //setProfileImagePreview( link || defaultImage);
        }
    }, [userData]);*/

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file && file.type.startsWith('image/') && file.size <= 2 * 1024 * 1024) { // 2MB limit
            setProfileImage(file);
            //console.log(file)
            //console.log(profileImage)
            const reader = new FileReader();
            reader.onloadend = () => {
                setProfileImagePreview(reader.result);
                setSaveReminder(true)
            };
            reader.readAsDataURL(file);
        } else {
            alert('Please upload a valid image file not exceeding 2MB.');
        }
    };

    const handleFormSubmit = async (e) =>{
        e.preventDefault()
        validateField('proofOfWork',proofOfWork)
        const hasErrors = Object.values(error).some((error) => error !== '');
        if (hasErrors) {
            return;
        }
        if(isUsernameAvailable && isUsernameAvailable === false){
            alert("Cannot Update: Username is not available")
            return;
        }
        if(urlErrors.length > 0){
            alert("Cannot Update: Check your url")
            return;
        }
        setIsPending(true)
        const formData = new FormData();
        formData.append('name', `${fname} ${lname}`);
        formData.append('email', email);
        formData.append('country', country);
        formData.append('username', username);
        formData.append('bio', bio);
        formData.append('proofOfWork', proofOfWork);
        if (profileImage) {
            formData.append('profileImage', profileImage);
        }

        try {
            const response = await axios.post('https://www.swaptime.co/swaptime_server/user/updateUser', formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });

            setIsPending(false);
            if (response.status === 200) {
                //console.log(response)
                //console.log(response.data.message)
                //console.log('User Registered');
                setServerError(null)
                setSaveReminder(false)
                setSuccessMessage(true)
                setTimeout(() => {
                    setSuccessMessage(false)
                }, 3000);
                onProfileUpdate()
            }else{
                setServerError(response.data.message)
            }
        } catch (error) {
            setIsPending(false);
            setServerError(error)
            console.error(error)
        }

    }
    useEffect(() => {
        // Fields to check for completion
        const fields = [fname, lname, username, email, bio, proofOfWork, profileImagePreview];
        
        
        // Calculate filled fields
        const filledFields = fields.filter(field => field && field.trim && field.trim() !== '').length
        
        // Total fields to track
        const totalFields = fields.length;
        
        // Update progress
        setProgress((filledFields / totalFields) * 100);
    }, [fname, lname, username, email, bio, proofOfWork, profileImagePreview]);
    

    const handleImagePlaceholderClick = () =>{
        document.getElementById('imageUploadInput').click();
    }

    const checkUsernameAvailability = async (username) => {
        try {
            const response = await axios.get(`https://www.swaptime.co/swaptime_server/user/checkUsername/${username}?userId=${userData._id}`);
            setIsUsernameAvailable(response.data.available);
            //console.log(isUsernameAvailable)
        } catch (error) {
            console.error("Error checking username availability:", error);
            setIsUsernameAvailable(null);
        }
    };

    const handleUsernameChange = (e) => {
        const value = e.target.value;
        setUsername(value);
        validateField('username', value);
        checkUsernameAvailability(value); // Call to check availability
    };

    // Function to validate and format the URL
const validateAndFormatURL = (url) => {
    let err = '';
    // Trim whitespace from the URL
    url = url.trim();

    // Check if the URL contains a top-level domain (e.g., .com, .net)
    const hasTopLevelDomain = /\.[a-z]{2,}$/i.test(url);

    if (!hasTopLevelDomain) {
        err = 'URL must contain a valid top-level domain (e.g., .com, .org)';
        setError((prevErrors) => ({ ...prevErrors, proofOfWork: err }));
        throw new Error(err);
    }

    // Check if the URL starts with "http://" or "https://"
    if (!/^https?:\/\//i.test(url)) {
        // If it starts with "www.", prepend "http://"
        if (url.startsWith('www.')) {
            url = 'http://' + url;
        } else {
            // Otherwise, prepend "http://"
            url = 'http://' + url;
        }
    }

    // Create a regular expression to validate the URL format
    const urlPattern = /^(http:\/\/|https:\/\/|www\.)[^\s/$.?#].[^\s]*$/i;

    // Validate the URL format
    if (!urlPattern.test(url)) {
        err = 'Invalid URL format';
        setError((prevErrors) => ({ ...prevErrors, proofOfWork: err }));
        throw new Error(err);
    }

    return url;
};


    // Function to handle input change
    const handleChange = (e) => {
        const input = e.target.value;
        setProofOfWork(input);

        // Split the input by commas to get individual URLs
        const urls = input.split(',').map(url => url.trim());
        const errors = [];

        // Validate each URL
        urls.forEach(url => {
            try {
                validateAndFormatURL(url);
            } catch (error) {
                errors.push(url); // Collect invalid URLs
            }
        });

        // Update the error state
        setUrlErrors(errors);
    };
    return (
        <section className="mt-4 mb-4" style={{color:"black"}}>
            <center>
                {isPending && <div className='h-screen text-slate-800 text-5xl mt-10' style={{width:"90%"}}><img src={loader1} alt='loader' /><br/>Saving Your Profile</div>}
                {
                    !isPending && 
                    <div style={{width:'70%'}}>
                        <form onSubmit={handleFormSubmit}  className='text-slate-800'>
                            <div className='mt-2 mb-4'>
                                <p className='text-primary text-4xl font-bold text-justify'>Complete Your Profile</p>
                            </div>
                            <div className="progress-bar bg-secondary rounded-full h-6 mb-4">
                                <div className="bg-primary h-6 rounded-full transition-all duration-500 pb-2 text-white font-bold" style={{ width: `${progress}%` }}><center>{Math.ceil(progress)}%</center></div>
                            </div>
                            {successMessage && <div className=" alert alert-success px-4 py-2 rounded-md">Profile Saved!</div>}
                            {serverError && <div className=" alert alert-danger px-4 py-2 rounded-md">{serverError.message}</div>}
                            {saveReminder && <div className=" alert alert-warning px-4 py-2 rounded-md">Remember to Save changes</div>}
                            <div className='row'>
                                <div className='mb-3 row'>
                                    <div className='col-md-6'>
                                        <input 
                                            type="file" 
                                            className="hidden" 
                                            placeholder="Upload Image"
                                            name="profileImage"
                                            accept='image/*'
                                            id="imageUploadInput"
                                            onChange={handleImageChange}
                                        />
                                        <div className='p-1 bg-light rounded-lg' >  
                                            <img src={profileImagePreview} onClick={handleImagePlaceholderClick} alt="Profile Preview" className="h-75 shadow rounded-circle cursor-pointer" style={{maxWidth:"200px", maxHeight:"200px"}} /> 
                                            Choose Picture
                                        </div> 
                                        
                                        {/*error.fname && <p style={{float:"left"}} className="text-red-500 mt-1 mb-3 ml-2">{error.fname}</p>*/}
                                    </div>
                                    <div className='col-md-6 mb-2'>
                                        <div className='col-md-12 mb-4'>
                                            <input 
                                                type="text" 
                                                id="fname"
                                                value={fname}
                                                onChange={(e) => {
                                                    setFName(e.target.value);
                                                    validateField('fname', e.target.value);
                                                }}
                                                onBlur={() => validateField('fname', fname)}
                                                className="rounded-lg px-6 py-4 w-full d-block" 
                                                placeholder="First Name"
                                                disabled = "disabled"
                                            />
                                            {/*error.fname && <p style={{float:"left"}} className="text-red-500 mt-1 mb-3 ml-2">{error.fname}</p>*/}
                                        </div>
                                        <div className='col-md-12'>
                                            <input 
                                                type="text" 
                                                id="lname"
                                                value={lname}
                                                onChange={(e) => {
                                                    setLName(e.target.value);
                                                    validateField('lname', e.target.value);
                                                }}
                                                onBlur={() => validateField('lname', fname)}
                                                className="rounded-lg px-6 py-4 w-full d-block" 
                                                placeholder="Last Name"
                                                disabled = "disabled"
                                            />
                                            {/*error.fname && <p style={{float:"left"}} className="text-red-500 mt-1 mb-3 ml-2">{error.fname}</p>*/}
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-12 mb-4'>
                                        <input
                                            type="text"
                                            id="username"
                                            value={username}
                                            onChange={handleUsernameChange}
                                            onBlur={() => validateField('username', username)}
                                            className="rounded-lg px-6 py-3 w-full d-block"
                                            placeholder="Username"
                                            name='username'
                                        />
                                        {isUsernameAvailable === false && <p style={{float:"left"}} className="text-danger mt-1 mb-3 ml-2">Username is taken</p>}
                                        {isUsernameAvailable === true && <p style={{float:"left"}} className="text-success mt-1 mb-3 ml-2">Username is available</p>}
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='mb-2 col-md-6'>
                                        <input 
                                            type="email" 
                                            id="email"
                                            value={email}
                                            onChange={(e) => {
                                                setEmail(e.target.value);
                                                validateField('email', e.target.value);
                                            }}
                                            onBlur={() => validateField('email', email)}
                                            className="rounded-lg px-6 py-3 w-full " 
                                            placeholder="Email Address"
                                            disabled = "disabled"
                                        />
                                        {/*error.email && <p style={{float:"left"}} className="text-red-500 mt-1 mb-3 ml-2">{error.email}</p>*/}
                                    </div>
                                    <div className='mb-2 col-md-6'>
                                        <select 
                                            name="country" 
                                            className="rounded-lg px-6 py-3 w-full" 
                                            value={country} 
                                            onChange={(e)=>{setCountry(e.target.value)}} 
                                            onBlur={() => validateField('country', country)}
                                            id="country"
                                            placeholder="Country of Residence"
                                        >
                                        {/* Conditional rendering of options */}
                                        {isResultPending ? (
                                            <option>Loading...</option>
                                        ) : result.country && result.country.length > 0 ? (
                                            <>
                                                <option value={userData.country._id}>{country}</option>{
                                                result.country.map((item) => (
                                                    <option key={item._id} value={item._id}>
                                                        {item.name}
                                                    </option>
                                                ))
                                                }
                                            </>
                                        ) : (
                                            <option value="">No countries available</option>
                                        )}
                                        
                                        </select>
                                        {error.country && <p style={{float:"left"}} className="text-danger mt-1 mb-3 ml-2">{error.country}</p>}
                                    </div>
                                </div>
                                <div className='mb-2 mt-2'>
                                    <div className='float-start'>
                                        My Bio
                                    </div><br />
                                    <textarea
                                        className='rounded-lg px-6 py-3 w-full'
                                        value={bio}
                                        onChange={handleBioChange}  // Use the new handler function
                                        onBlur={() => validateField('bio', bio)}
                                        placeholder="Write your bio here..."
                                    ></textarea>
                                    <div className="float-end">
                                        {remainingChars}/{maxCharacters}
                                    </div>
                                </div>
                                {/*<div className='mb-2 mt-2 items-start'>
                                    <div className='float-start'>
                                        Skills you require <small>(If more than one, separate each skill by a comma)</small>
                                    </div><br/>
                                    <textarea 
                                        className='rounded-lg px-6 py-3 w-full' 
                                        value={skillsRequired} 
                                        onChange={(e)=>{setSkillsRequired(e.target.value)}}
                                        onBlur={() => validateField('skillsRequired', skillsRequired)}
                                    ></textarea>
                                </div>*/}
                                <div className='mb-2 mt-2'>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <label className='font-weight-bold'>
                                            Proof of Work (URLs) <small>(If more than one, separate each by a comma)</small>
                                        </label>
                                    </div>
                                    <input
                                        type="text"
                                        name='proofOfWork'
                                        className='form-control rounded-lg px-6 py-3'
                                        value={proofOfWork}
                                        placeholder='Enter URLs separated by commas'
                                        onChange={(e) => {
                                            setProofOfWork(e.target.value);
                                            validateField('proofOfWork', e.target.value); // Changed to e.target.value for accurate validation
                                        }}
                                    />
                                    <div className='mt-2'>
                                        {proofOfWork.split(',').map((url, index) => {
                                            const trimmedUrl = url.trim();
                                            return (
                                                <div key={index} className='d-flex align-items-center' style={{ marginBottom: '5px' }}>
                                                    <span
                                                        style={{
                                                            color: urlErrors.includes(trimmedUrl) ? 'red' : 'black',
                                                            textDecoration: urlErrors.includes(trimmedUrl) ? 'underline' : 'none',
                                                            backgroundColor: urlErrors.includes(trimmedUrl) ? '#f8d7da' : 'transparent', // Light red background for errors
                                                            borderRadius: '5px',
                                                            padding: '5px 10px', // Added padding for better appearance
                                                            display: 'inline-block'
                                                        }}
                                                    >
                                                        {trimmedUrl}
                                                    </span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>

                                {error.proofOfWork && <p className="text-danger mt-1 mb-3 ml-2">{error.proofOfWork}</p>}
                                <div className='mb-2 mt-1'>
                                    <button type="button" style={{backgroundColor:"black", padding:"4px", paddingBottom:"6px", borderRadius:"10px", color:"#fff" }} onClick={handleFormSubmit} className='border-1 border-solid border-gray-800 bg-orange-200 hover:bg-zinc-900 text-white w-full px-3 pt-2'>Save Profile</button>
                                </div>
                            </div>
                        </form>  
                    </div>
                }
            </center>
        </section>
    );
}

export default OnboardForm;