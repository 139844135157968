//import Navbar from "../components/Navbar";
import '../assets/styles/style.css';
import '../assets/styles/responsive.css';
import '../assets/styles/font-awesome.min.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import LoginForm from "../components/auth/LoginForm"


const ThankYouPage = () => {
    return (
      <div className="flex items-center justify-center h-screen bg-gray-100">
        <div className="bg-white rounded-lg shadow-md px-8 py-6 max-w-md">
          <h1 className="text-2xl font-medium text-center mb-4">Thank you for registering!</h1>
          <p className="text-gray-700 text-center">
            We have received your registration and will be contacting you shortly.
          </p>
        </div>
      </div>
    );
  };
  
  export default ThankYouPage;